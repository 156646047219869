
import { SET_USERCONFIG } from './../actions'


export const setUserConfiguration = (state=null, action) => {
    
    switch (action.type) {
        case SET_USERCONFIG:
         return {...state,
            Id:action.payload.Id,
            UserName:action.payload.UserName,
            Home:action.payload.Home,
            OpenModalHome:action.payload.OpenModalHome,
            Site:action.payload.Site,
            IP:action.payload.IP,
            }
      
        default:
           return {...state}
           
    }
  //  return state;
}