import { SET_ITEMSCONTAINER } from '../actions'


export const setItmesContainer = (state=null, action) => {
    
    switch (action.type) {
        case SET_ITEMSCONTAINER:
          
         return {...state,
            holder0:action.payload.holder0,
            holder1:action.payload.holder1,
            holder2:action.payload.holder2,
            holder3:action.payload.holder3,
            holder4:action.payload.holder4,
            holder5:action.payload.holder5,
            holder6:action.payload.holder6,
            holder7:action.payload.holder7,
            
            }
      
        default:
           return {...state}
           
    }
  //  return state;
}