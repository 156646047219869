import _ from 'lodash'
import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';

import { setModalStatus,cleanSearch, startSearch, updateSelection, finishSearch, _LoadModels } from './../../actions'
import { Modal, Search, Header, Label, Icon } from 'semantic-ui-react'
import VideoPage from './../../pages/VideoPage'

import { ADMINFILES } from './../../constants/constants'

import ModalSearch from './ModalSearch'



class SearchCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openVideoModal: false,
            vid_gal: 0,
            size_modal: 0,
            modelsSelected: 0,
            openthis:false,
            _item:[],
            _related_scenes:[],
            lookfor:'',
        }
    }
    
    componentDidMount() {
        this.props._LoadModels();
    }
   
    HandleModelButton = (v) => {
        const modelSceneUri = `${ADMINFILES}/relatedScenes_v2.php?`;
        const url_api = `${modelSceneUri}&action=related-by-model&id=${v.id}&rand=${Math.random()}`;

        axios({
            method: "GET",
            url: `${url_api}`,

        }).then((response) => {
            if (response.data.length > 0) {
                this.setState({ openVideoModal: true, vid_gal: 4, size_modal: 1, modelsSelected: response.data })
            } else if (response.data.length === 0) {
                alert("Model Info not Found..!");
            }
        }).catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });


    }
    isMatch = (result) => {
       
        const re = new RegExp("^"+this.state.lookfor+".", 'i');
       
        return re.test(result.title)
    }
    handleSearchChange = (e, data) => {
       // clearTimeout();
      this.setState({lookfor: data.value})
      
      this.props.startSearch({ query: data.value })
    

        setTimeout(() => {
            if (data.value.length === 0) {
                this.props.cleanSearch();
                return
            }

            this.props.finishSearch({ results: _.filter(this.props.source, this.isMatch), })

        } , 300)
    }

    setVideoModalOpen = (v) => {
        this.setState({ openVideoModal: v })
    }
    setModalOpenRelated = (v) => {
        
        this.setVideoModalOpen(false)
        this.setState({ openthis: true })
        
       
        const { related_scenes } = this.props;
        if(related_scenes){
            related_scenes.map( (item,i) =>{ if(i===0){this.setState({ _item:item}) ;} return null;})
            this.setState({ _related_scenes:related_scenes})
        }
        
        this.props.setModalStatus({ ModalStatus: true });
      
                
    }
    setModalClose = () => {
        this.setState({ openthis: false })
        this.props.setModalStatus({ ModalStatus: false });

    }
    resultRenderer = ({ title }) => <Label content={title} />
    render() {


        const { loading, results, } = this.props.searchstate
    
        //let modalItem = this.state._item; 



        return (
            
                    <>
                    <Search 
                        size="tiny"

                        key="serachmenu"
                        loading={loading}
                        onResultSelect={(e, data) => {
                            this.HandleModelButton(data.result)
                            this.props.updateSelection({ selection: data.result.title })
                        }
                        }
                        onSearchChange={this.handleSearchChange}
                        resultRenderer={this.resultRenderer}
                        results={results}
                        value={this.state.lookfor}
                    />
                    <Modal
                        onClose={() => this.setVideoModalOpen(false)}
                        open={this.state.openVideoModal}
                        size={this.state.size_modal === 0 ? "fullscreen" : "large"}
                    >

                        <Modal.Content>
                            <VideoPage searchsetModalOpenRelated={this.setModalOpenRelated} cdntime={this.props.zoomState.cdntime} videoItem={[]} vid_gal={this.state.vid_gal} modelsSelected={this.state.modelsSelected} handleClose={this.handlelevelcero}></VideoPage>
                            <div className="Info_vid_page">
                                <div className="Info_vid_SubContent_page" >
                                    <Header as='h2' inverted textAlign='left'>

                                        <Icon link circular  name='arrow left' onClick={() => this.setVideoModalOpen(false)} className="blink"/>

                                    </Header>


                                </div>
                            </div>
                        </Modal.Content>

                    </Modal>
                    {/*---------------------------*/}
                    <ModalSearch 
                        openthis={this.state.openthis}
                        setModalClose={this.setModalClose}
                     />
                    
                   </>
              
        )
    }
}
const mapSetsToProps = state => ({
    searchstate: state.searchManager,
    source: state.models.source,
    zoomState: state.zoomState,
    related_scenes: state.setModelScenes.related_scenes,
    itemsHolder: state.setItmesContainer,
    ModalStatus: state.setModalStatus.ModalStatus
});
const mapDispatchToPropsActions = dispatch => ({
    cleanSearch: value => dispatch(cleanSearch(value)),
    startSearch: value => dispatch(startSearch(value)),
    updateSelection: value => dispatch(updateSelection(value)),
    finishSearch: value => dispatch(finishSearch(value)),
    _LoadModels: value => dispatch(_LoadModels(value)),
    setModalStatus: value => dispatch(setModalStatus(value)),



});
export default connect(mapSetsToProps, mapDispatchToPropsActions)(SearchCustom);

