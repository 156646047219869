import PropTypes from 'prop-types'
import React, { Component } from 'react'
//import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { setNewUserConfiguration, setresetView,getFavorites,setModalStatus } from './../../actions'
import {VERSION} from './../../constants/constants';
import './../Styles.css';
import SimpleMenu from '../hook/SimpleMenu';
import { isMobile } from 'react-device-detect';
import { Button,Transition,Image,List, Grid,  Divider,Segment,Container} from 'semantic-ui-react'

import Favorites from '../helpers/Favorites'
import ContactUs from '../helpers/ContactUs'
import Extras from '../helpers/Extras'
import logo from './../holder/logo.png'
import logo_footer from './../holder/patent_logo.png'
import './layout.css';

class MobileContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      classMenu:true,
      visibleFooter: false,
      openthis:false
      ,height:0,
      opneContactUs:false
      ,openExtrasM:false,idEx:0
    }
  }

  toggleVisibility = () => {
    if(!this.state.visibleFooter){
      setTimeout(this.toggleVisibility, 5000);
    }
    this.setState({ visibleFooter: this.state.visibleFooter ? false : true })
    setTimeout(this.h, 1500);
    
  }

  ChangeClassMenu =()=> {
  
    this.setState({classMenu : false  })
    
  }
  ChangeClassMenu2 =()=> {
    
    this.setState({classMenu : true  })
    
    
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  renderRedirect = () => {


    let path = `DashBoard`;
    window.location = path;

  }
  renderRedirect2 = () => {
    let path = `/`;
    window.location.hash = path;


  }
  handlelinkshomes = (v) => {
    //this.props.setresetView({ IsresetingView: [true, 0, 0, "easeInCubic"] });
    this.handleSidebarHide();
    this.props.setNewUserConfiguration({ action: 'setHome', Home: v, zoommState: this.props.zoomState.zoommState, scale: this.props.scaleme, sets_data: this.props.sets_data });

  }
  handleFavoritemodal = () => {
    this.setState({ openthis: true })
      this.props.getFavorites({UserName:this.props.UserConfiguration.UserName});
      const { favorite_scenes } = this.props;
      if(favorite_scenes && this.props.ModalStatus){
        this.setState({ openthis: true })
      }
   
              
  
  }
  setFavoriteModalClose = () => {
    this.props.setModalStatus({ ModalStatus: false });
    this.setState({ openthis: false })
    

}
  setContactModalClose = () => {
    
    this.setState({ opneContactUs: false })
    this.props.setModalStatus({ ModalStatus: false });

}
  handleContactModal = () => {
    this.props.setModalStatus({ ModalStatus: true });
    this.setState({opneContactUs: true })
    
  }
  
setExtrasModalClose = () => {
    
    this.setState({ openExtrasM: false })
    this.props.setModalStatus({ ModalStatus: false });

    
}
handleExtrasModal = (e) => {

  this.setState({openExtrasM: true,idEx:e })
  this.props.setModalStatus({ ModalStatus: true });
    
    
  }
  h= () =>{
   if(this.state.visibleFooter){
    const hh = document.getElementById('menuroot_footer').clientHeight;
    this.setState({  height:hh });
    
   }
       
       
  }
 
  render() {
    const { children } = this.props
   
    const { visibleFooter } = this.state
    
    return (
      <div>
       <SimpleMenu ChangeClassMenu={this.ChangeClassMenu} 
     ChangeClassMenu2={this.ChangeClassMenu2} 
     handlelinkshomes={this.handlelinkshomes}
     handleFavoritemodal={this.handleFavoritemodal} 
     handleContactModal={this.handleContactModal} 
     handleExtrasModal={this.handleExtrasModal} 
     classMenu={this.state.classMenu}/>
 
          {children}


          <Button className='button-opacity' style={{ bottom: visibleFooter ? !isMobile ? this.state.height : this.state.height : '5%', right: 0, position: 'absolute' }}
                content={visibleFooter ?  'Hide Footer' : 'Show Footer'}
                
                onClick={()=>{this.toggleVisibility();}}
          />

            
            <Transition animation='fly up' duration='2000'  visible={this.state.visibleFooter} >
           <Segment inverted vertical className="menuroot" id="menuroot_footer" >
          <Container textAlign='center' fluid >
          <Grid inverted={true} padded centered>
             <Grid.Column className="nopadding">
                <Image src={logo} centered size='tiny' />
              </Grid.Column>
              <Grid.Column className="nopadding"> <Image src={logo_footer} centered size='tiny' /></Grid.Column>
           </Grid>
            <List  inverted  link size='small'>
              {/*<List.Item as='a' onClick={()=>this.handleExtrasModal(1)}>
                
                  2257 statement</List.Item>
              <List.Item as='a' href='#'>
                    Vendo is our authorized reseller along with CCBill.
              </List.Item>*/}
              <List.Item as='a' href='#'>
                  Site operated by Mania Media Inc. {/*, 54 de la Cité-des-Jeunes, Suite 102B-217, Vaudreuil-Dorion, QC, J7V 9L5, Canada. 
            
                  EU reference International Content Creators SL, Avenida Diagonal 493-495, Pral-2a, 08029, Barcelona, Spain*/}
              </List.Item>
              
              </List>

              {/*<List horizontal divided inverted  link size='small'  className="nomargin" >
                  <List.Item as='a' > Support</List.Item>
                  <List.Item as='a' >Faqs </List.Item>
                  <List.Item as='a' >Terms of use</List.Item>
                  <List.Item as='a' >Model for us</List.Item>
                  <List.Item as='a' >Affiliates</List.Item>
            </List>  
              <Divider hidden className="nomargin" />
              <List horizontal divided inverted  link size='small'  className="nomargin" >
              <List.Item  as='a' href='https://www.iubenda.com/privacy-policy/72380912' target='_blank' className="iubenda-nostyle no-brand iubenda-embed">
                Privacy Policy
              </List.Item>
              <List.Item as='a'  target='_blank' className={'iubenda-nostyle no-brand iubenda-embed'} href='https://www.iubenda.com/privacy-policy/72380912/cookie-policy'>
               Cookie Policy
              </List.Item>
              <List.Item as='a' href='#'>
                          © 2020 Mania Media Inc. All rights reserved. {VERSION}
              </List.Item>
              
              
            </List>*/}
          </Container>
        </Segment> 
              
               
             </Transition>
             
    {/*       
<div id="HL">
          <HomeLocation key='footer' visibleFooter={this.state.visibleFooter} handleContactModal={this.handleContactModal} handleExtrasModal={this.handleExtrasModal}></HomeLocation>
          </div>

       </Sidebar.Pusher> */} 
       <Favorites openthis={this.state.openthis} handleFavoritemodal={this.handleFavoritemodal} setModalClose={this.setFavoriteModalClose}/>
       <ContactUs opneContactUs={this.state.opneContactUs} setContactModalClose={this.setContactModalClose}/>
       
          <Extras openExtras={this.state.openExtrasM} setExtrasModalClose={this.setExtrasModalClose} idEx={this.state.idEx}/>
      </div>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}
const mapSetsToProps = state => ({
  zoomState: state.zoomState
  , ModalStatus: state.setModalStatus.ModalStatus
  , UserConfiguration: state.setUserConfiguration
  , IsLoading: state.loaderTransition.IsLoading
  , setNumPage: state.setNumPage
  , sets_data: state.setsSiteController.sets_data
  , coords: state.preLoadCorrds.coords
  , Items: state.itemsDisplayed.Items
  , UsedCoords: state.itemsDisplayed.UsedCoords
  , favorite_scenes: state.getFavoriteScenes.favorite_scenes
  

}) //state.setModalStatus.ModalStatus

const mapDispatchToPropsActions = dispatch => ({
  setNewUserConfiguration: value => dispatch(setNewUserConfiguration(value)),
  setresetView: value => dispatch(setresetView(value)),
  getFavorites: value => dispatch(getFavorites(value)),
  setModalStatus: value => dispatch(setModalStatus(value)),

});

export default connect(mapSetsToProps, mapDispatchToPropsActions)(MobileContainer);