import { stateGetStyles, _handlePanningintoState } from './../services/states'
import { coordGenerator, evaluateCoords, maxValueInArray } from './../services/coordGenerator'
import { ADMINFILES,CAT1 } from './../constants/constants'
import { CoordsToSets } from './../components/CoordsToSets';
export const SET_ZOOMSTATUS = 'SET_ZOOMSTATUS'
export const SET_INITIALSTATE = 'SET_INITIALSTATE'
export const SET_GETSTYLES = 'SET_GETSTYLES'
export const SET_MODALSTATE = 'SET_MODALSTATE'
export const SET_USERCONFIG = 'SET_USERCONFIG'
export const SET_SETLIST = 'SET_SETLIST'
export const SET_MODELSCENES = 'SET_MODELSCENES'
export const SET_LOADERMORE = 'SET_LOADERMORE'
export const SET_ITEMSCONTAINER = 'SET_ITEMSCONTAINER'

export const SET_ITEMSDISPLAYED = 'SET_ITEMSDISPLAYED'

export const SET_PRELOADCOORDS = 'SET_PRELOADCOORDS'
export const SET_VIDEOLOADED = 'SET_VIDEOLOADED'
export const SET_LOADERTRANSITION = 'SET_LOADERTRANSITION'
export const SET_ITEMMODALOPEN = 'SET_ITEMMODALOPEN'
export const SET_RESETVIEW = 'SET_RESETVIEW'
export const SET_LEVELZOOM = 'SET_LEVELZOOM'
export const GET_FAVORITESCENES = 'GET_FAVORITESCENES'
export const DELETE_COMMENT = 'DELETE_COMMENT'

export const CLEAN_QUERY = 'CLEAN_QUERY'
export const START_SEARCH = 'START_SEARCH'
export const FINISH_SEARCH = 'FINISH_SEARCH'
export const UPDATE_SELECTION ='UPDATE_SELECTION'
export const LOAD_MODELS ='LOAD_MODELS'



export const cleanSearch = payload => ({ type: CLEAN_QUERY, payload });
export const startSearch = payload => ({ type: START_SEARCH, payload });
export const updateSelection = payload => ({ type: UPDATE_SELECTION, payload });
export const finishSearch = payload => ({ type: FINISH_SEARCH, payload });
export const loadModels = payload => ({ type: LOAD_MODELS, payload });
export const rideManager = payload => ({ type: UPDATE_SELECTION, payload });

export const setlevelZoom = payload => ({ type: SET_LEVELZOOM, payload });

export const getFavoriteScenes = payload => ({ type: GET_FAVORITESCENES, payload });

export const DeleteFavoriteScenes = payload => ({ type: DELETE_COMMENT, payload });

export const setZoomStatus = payload => ({ type: SET_ZOOMSTATUS, payload });

export const getinitState = payload => ({ type: SET_INITIALSTATE, payload });

export const setModalStatusb = payload => ({ type: SET_MODALSTATE, payload })

export const setLoadingMore = payload => ({ type: SET_LOADERMORE, payload })

export const setItmesContainer = payload => ({ type: SET_ITEMSCONTAINER, payload })

export const setloaderTransition = payload => ({ type: SET_LOADERTRANSITION, payload })

export const setresetView = payload => ({ type: SET_RESETVIEW, payload })

export const setitemModalOpen = payload => ({ type: SET_ITEMMODALOPEN, payload })






export const preLoadCorrds = payload => ({ type: SET_PRELOADCOORDS, payload })

export const getpreNewLoadCorrds = payload => {
    return dispatch => {


        let coords = coordGenerator(payload.Home, payload.zoommState, payload.sets_data);
        dispatch(preLoadCorrds({ coords: coords }));

        /*let rg = false; //por defecto false
        if (payload.reGenerate !== undefined) {
            if (payload.reGenerate) {
                rg = true;
            }
        }*/

        if (payload.posX === undefined) {
            dispatch(_CoordsToSets({ clickX: 0, clickY: 0, coords, alreadyAdded: [], positionX: 0, positionY: 0, UsedCoords: [], zoommState: payload.zoommState, scale: payload.scale, sets_data: payload.sets_data, reGenerate: true }))
        } else {
            dispatch(_CoordsToSets({ clickX: 0, clickY: 0, zoommState: payload.zoommState, coords, alreadyAdded: [], positionX: 0, positionY: 0, UsedCoords: [], sets_data: payload.sets_data, Home: payload.Home, page: payload.page, scale: payload.scale, reGenerate: true }))
        }
    }
}
export const getpreLoadCorrds = payload => {
    return dispatch => {


        let coords = coordGenerator(payload.home, payload.zoommState, payload.sets_data);
        dispatch(preLoadCorrds({ coords: coords }));

        let rg = false; //por defecto false
        if (payload.reGenerate !== undefined) {
            if (payload.reGenerate) {
                rg = true;
            }
        }
        if (payload.posX === undefined) {
            dispatch(_CoordsToSets({ clickX: 0, clickY: 0, coords, alreadyAdded: [], positionX: 0, positionY: 0, UsedCoords: [], zoommState: payload.zoommState, scale: payload.scale, sets_data: payload.sets_data, reGenerate: rg }))
        } else {
            dispatch(_CoordsToSets({ clickX: 0, clickY: 0, zoommState: payload.zoommState, coords, alreadyAdded: [], positionX: 0, positionY: 0, UsedCoords: [], sets_data: payload.sets_data, Home: payload.Home, page: payload.page, scale: payload.scale, reGenerate: rg }))
        }
    }

}

export const itemsDisplayed = payload => ({ type: SET_ITEMSDISPLAYED, payload })

export const _CoordsToSetsResetImg = payload => {
    return dispatch => {
        let scale = 1;
        if (payload.scale > 4) {
            scale = 6;
            if (payload.levelZoom !== scale) {
                dispatch(setlevelZoom({ levelZoom: scale }));
            }
        } else {
            if (4 > payload.scale && payload.scale > 3) {
                scale = 1;
                if (payload.levelZoom !== scale) {
                    dispatch(setlevelZoom({ levelZoom: scale }));
                }
            } else {
                if (3 > payload.scale && payload.scale > 2) {
                    scale = 3;
                    if (payload.levelZoom !== scale) {
                        dispatch(setlevelZoom({ levelZoom: scale }));
                    }
                } else {
                    if (2 > payload.scale && payload.scale > 1) {
                        scale = 3;
                        if (payload.levelZoom !== scale) {
                            dispatch(setlevelZoom({ levelZoom: scale }));
                        }
                    } else {
                        scale = 3;

                        dispatch(setlevelZoom({ levelZoom: scale }));

                    }
                }
            }
        }

        /* let items_x = ResetImagesToSets(payload.positionX, payload.positionY, payload.zoommState, payload.scale, payload.previousScale, payload.alreadyAdded);
         if (items_x.length > 0) {
             dispatch(itemsDisplayed({ Items: items_x, UsedCoords: payload.UsedCoords }));
 
         }*/
    }
}
export const _GenerateMoreCoords = payload => {
    return dispatch => {
        const S_state = payload.zoommState;
        let allMap = payload.coords.coordenadas;
        let allMap1 = evaluateCoords(allMap, payload.coords.limites[0], 0);//x+
        let allMap2 = evaluateCoords(allMap, 0, payload.coords.limites[1]);//y+
        let allMap3 = evaluateCoords(allMap, payload.coords.limites[2], 0);//x-
        let allMap4 = evaluateCoords(allMap, 0, payload.coords.limites[3]);//y-*/

        let allMap1b = evaluateCoords(allMap, payload.coords.limites[0], payload.coords.limites[3]);//y+
        let allMap2b = evaluateCoords(allMap, payload.coords.limites[0], payload.coords.limites[1]);//y+
        let allMap3b = evaluateCoords(allMap, payload.coords.limites[2], payload.coords.limites[1]);//y+
        let allMap4b = evaluateCoords(allMap, payload.coords.limites[2], payload.coords.limites[3]);//y+

        allMap1.map((itme) => { allMap.push(itme); return itme });
        allMap2.map((itme) => { allMap.push(itme); return itme });
        allMap3.map((itme) => { allMap.push(itme); return itme });
        allMap4.map((itme) => { allMap.push(itme); return itme });

        allMap1b.map((itme) => { allMap.push(itme); return itme });
        allMap2b.map((itme) => { allMap.push(itme); return itme });
        allMap3b.map((itme) => { allMap.push(itme); return itme });
        allMap4b.map((itme) => { allMap.push(itme); return itme });

        let limits = [maxValueInArray(allMap, 0, "1", S_state) + 10
            , maxValueInArray(allMap, 1, "1", S_state) + 10
            , maxValueInArray(allMap, 0, "-1", S_state) - 5
            , maxValueInArray(allMap, 1, "-1", S_state) - 5];



        // console.log(limits,"S_state=>",S_state);
        /*allMap.map((itmes) => {
            if((itmes[0] <= (limits[0]/2) && itmes[1]<=(limits[1]/2)) || (itmes[0] >= (limits[2]/2) && itmes[1]>=(limits[3]/2))){
                yx.push(itmes);
            }
            if((itmes[0] >= (limits[0]/2) && itmes[1]>=(limits[1]/2)) || (itmes[0] <= (limits[2]/2) && itmes[1]<=(limits[3]/2))){
                ymx.push(itmes);
            }
            return itmes;
          
        })*/
        let CA = [];//(x,y) X>=0
        let CB = [];//(x,-y) X>=0

        let CC = [];//(-x,y) y>=0
        let CD = [];//(-x,-y) y<=0


        allMap.map((itmes) => {
            if (parseInt(itmes[0]) >= 0 && parseInt(itmes[1]) >= 0) {
                CA.push(itmes);
            }
            if (parseInt(itmes[0]) >= 0 && parseInt(itmes[1]) <= 0) {
                CB.push(itmes);
            }
            if (parseInt(itmes[0]) <= 0 && parseInt(itmes[1]) >= 0) {
                CC.push(itmes);
            }
            if (parseInt(itmes[0]) <= 0 && parseInt(itmes[1]) <= 0) {
                CD.push(itmes);
            }
            return true;

        })
        let newcoords = { limites: limits, coordenadas: allMap, splets: [CA, CB, CC, CD] }
        dispatch(preLoadCorrds({ coords: newcoords }));
    }
}
export const _CoordsToSets = payload => {


    return dispatch => {

        let alreadyAdded = [];
        if (!payload.reGenerate) {
            alreadyAdded = payload.alreadyAdded
        }

        let misetcargados = [];

        if (payload.alreadyAdded.length > 0) {
            for (let h = 0; h < payload.alreadyAdded.length; h++) {
                misetcargados.push(payload.alreadyAdded[h]);

            }
        }
        //-----------LOAD MORE COORDS-------------------


        if (2 === 0 && payload.isGenerating !== undefined && payload.isGenerating) {

            let allMap = payload.coords.coordenadas;

            let allMap1 = evaluateCoords(allMap, payload.coords.limites[0], 0);//x+
            let allMap2 = evaluateCoords(allMap, 0, payload.coords.limites[1]);//y+
            let allMap3 = evaluateCoords(allMap, payload.coords.limites[2], 0);//x-
            let allMap4 = evaluateCoords(allMap, 0, payload.coords.limites[3]);//y-*/

            allMap1.map((itme) => { allMap.push(itme); return itme });
            allMap2.map((itme) => { allMap.push(itme); return itme });
            allMap3.map((itme) => { allMap.push(itme); return itme });
            allMap4.map((itme) => { allMap.push(itme); return itme });


            let limits = [maxValueInArray(allMap, 0, "1", payload.zoommState)
                , maxValueInArray(allMap, 1, "1", payload.zoommState)
                , maxValueInArray(allMap, 0, "-1", payload.zoommState)
                , maxValueInArray(allMap, 1, "-1", payload.zoommState)];
            let yx = payload.coords.splets[0];
            let ymx = payload.coords.splets[1];

            allMap1.map((itmes) => {
                if ((itmes[0] <= (limits[0] / 2) && itmes[1] <= (limits[1] / 2)) || (itmes[0] >= (limits[2] / 2) && itmes[1] >= (limits[3] / 2))) {
                    yx.push(itmes);
                }
                if ((itmes[0] >= (limits[0] / 2) && itmes[1] >= (limits[1] / 2)) || (itmes[0] <= (limits[2] / 2) && itmes[1] <= (limits[3] / 2))) {
                    ymx.push(itmes);
                }
                return itmes;
            })
            allMap2.map((itmes) => {
                if ((itmes[0] <= (limits[0] / 2) && itmes[1] <= (limits[1] / 2)) || (itmes[0] >= (limits[2] / 2) && itmes[1] >= (limits[3] / 2))) {
                    yx.push(itmes);
                }
                if ((itmes[0] >= (limits[0] / 2) && itmes[1] >= (limits[1] / 2)) || (itmes[0] <= (limits[2] / 2) && itmes[1] <= (limits[3] / 2))) {
                    ymx.push(itmes);
                }
                return itmes;
            })
            allMap3.map((itmes) => {
                if ((itmes[0] <= (limits[0] / 2) && itmes[1] <= (limits[1] / 2)) || (itmes[0] >= (limits[2] / 2) && itmes[1] >= (limits[3] / 2))) {
                    yx.push(itmes);
                }
                if ((itmes[0] >= (limits[0] / 2) && itmes[1] >= (limits[1] / 2)) || (itmes[0] <= (limits[2] / 2) && itmes[1] <= (limits[3] / 2))) {
                    ymx.push(itmes);
                }
                return itmes;
            })
            allMap4.map((itmes) => {
                if ((itmes[0] <= (limits[0] / 2) && itmes[1] <= (limits[1] / 2)) || (itmes[0] >= (limits[2] / 2) && itmes[1] >= (limits[3] / 2))) {
                    yx.push(itmes);
                }
                if ((itmes[0] >= (limits[0] / 2) && itmes[1] >= (limits[1] / 2)) || (itmes[0] <= (limits[2] / 2) && itmes[1] <= (limits[3] / 2))) {
                    ymx.push(itmes);
                }
                return itmes;
            })

            let newcoords = { limites: limits, coordenadas: allMap, splets: [yx, ymx] }

            //dispatch(preLoadCorrds({ coords: newcoords }));

            let items_x = CoordsToSets(payload.clickX, payload.clickY, newcoords, payload.positionX, payload.positionY, payload.UsedCoords, payload.zoommState, payload.scale, payload.sets_data, misetcargados);



            if (items_x[0].length > 0) {

                items_x[0].map((item, i) => { return alreadyAdded.push(item) });
                dispatch(setloaderTransition({ IsLoading: false }));
                dispatch(itemsDisplayed({ Items: alreadyAdded, UsedCoords: items_x[1] }));

            }
        } else {
            let items_x = CoordsToSets(payload.clickX, payload.clickY, payload.coords, payload.positionX, payload.positionY, payload.UsedCoords, payload.zoommState, payload.scale, payload.sets_data, misetcargados);



            if (items_x[0].length > 0) {
                // dispatch(setLoadingMore({letload:false}));
                items_x[0].map((item, i) => { return alreadyAdded.push(item) });
                dispatch(setloaderTransition({ IsLoading: false }));
                dispatch(itemsDisplayed({ Items: alreadyAdded, UsedCoords: items_x[1] }));
                //dispatch(setLoadingMore({letload:true}));
            }

        }
        //----------------------------------------------


    }
}
/////////////////////////////////////////////////////////////////////////////////// this.props.coords,positionX,positionY
export const setSetStyles = payload => {
    return dispach => {

        let newpayload = stateGetStyles(payload.panningendX, payload.panningendY, payload);
        setZoomStatus({ resetTransform: false });
        dispach(setZoomStatus(newpayload));
    }
}
export const pushStyles = payload => {

    return dispatch => {
        if (!payload.panningBlock) {
            let newpayload = _handlePanningintoState(payload, payload.panningendX, payload.panningendY);
            if (newpayload.pushintostate) {
                let newpayload2 = stateGetStyles(payload.panningendX, payload.panningendY, newpayload, true);
                setZoomStatus({ resetTransform: false, panningBlock: true, lockAxisX: newpayload.lockAxisX, lockAxisY: newpayload.lockAxisY });
                newpayload2.resetTransform = false;
                newpayload.pushintostate = false;

                /*if (newpayload2.itemsStyles.length >= (payload.page * NUMPERPAGE)) {
                    let page = Math.ceil((newpayload2.itemsStyles.length / NUMPERPAGE));
                    dispatch(setNumPage({ page: page }));
                    dispatch(loadMoreSets({ home: payload.Home, sets_data: payload.sets_data, page: page }))

                }*/

                dispatch(setZoomStatus(newpayload2));
            }
        }
    }
}
export const setModalStatus = payload => {
    return dispatch => {
        dispatch(setModalStatusb(payload))
    }
}

export const setUserConfiguration = payload => ({ type: SET_USERCONFIG, payload })


const url = `${ADMINFILES}memberInfo.php?`
//payload load 
export const getUserConfiguration = payload => {

    const url_api = `${url}&Action=${payload.action}&rand=${Math.random()}`;

    return dispatch => {

        return fetch(url_api).then(
            data => (data.json())
        ).then(
            userInfo => {
                let OpenModalHome = false;
                if (parseInt(userInfo[0].Home) === 0) {
                    OpenModalHome = true;
                }

                dispatch(getSetsConfiguration({ home: userInfo[0].Home, zoommState: payload.zoommState, scale: payload.scale,HomeType:userInfo[0].HomeType }));
                dispatch(setUserConfiguration({ Id: userInfo[0].Id, UserName: userInfo[0].UserName, Home: userInfo[0].Home, OpenModalHome, IP: userInfo[0].IP,HomeType:userInfo[0].HomeType }));
            }
        )
    }
}

export const setNewUserConfiguration = payload => {
/**
 * Hometypepara diferenciar la carga de items
 */
    const url_api = `${url}&Action=${payload.action}&home=${payload.Home}&hometype=${payload.typeHome}&rand=${Math.random()}`;

    return dispatch => {

        //dispatch(setloaderTransition({ IsLoading: true }));

        return fetch(url_api).then(
            data => (data.json())
        ).then(
            userInfo => {
                let OpenModalHome = false;
                window.location.reload();
                if (parseInt(userInfo[0].Home) === 0) {
                    OpenModalHome = true;
                }
                if (payload.zoommState) {
                    dispatch(getSetsConfiguration({ home: (userInfo[0].Home!==0)?userInfo[0].Home:CAT1, zoommState: payload.zoommState, reGenerate: true, scale: payload.scale, sets_data: payload.sets_data }));
                    
                } else {
                    dispatch(getSetsConfiguration({ home: (userInfo[0].Home!==0)?userInfo[0].Home:CAT1 }));
                }

                dispatch(setUserConfiguration({ Id: userInfo[0].Id, UserName: userInfo[0].UserName, Home: userInfo[0].Home, OpenModalHome, IP: userInfo[0].IP  }));
                dispatch(setresetView({ IsresetingView: [false, 0, 0, "easeInCubic"] }));
                
            }

        )

    }

}
/////////////////////////////////////////
//concatSets setsSiteController
////////////////////////////////////////
const setsSiteController = payload => ({ type: SET_SETLIST, payload })
//const setUri = "https://newworlddemo.com/demo/setLoader_v4.php?rand=" ;
const setUri = `${ADMINFILES}setLoader_v4.php?rand=`


export const getSetsConfiguration = payload => {
    return dispatch => {
        if (payload.sets_data !== undefined) {


            //dispatch(getpreLoadCorrds({ zoommState: payload.zoommState,scale:payload.scale,sets_data : sets_data }));
            if (payload.reGenerate !== undefined) {
                dispatch(getpreLoadCorrds({ zoommState: payload.zoommState, scale: payload.scale, sets_data: payload.sets_data, home: payload.home, reGenerate: payload.reGenerate }));

            } else {
                dispatch(getpreLoadCorrds({ zoommState: payload.zoommState, scale: payload.scale, sets_data: payload.sets_data, home: payload.home }));
            }


        } else {

            const url_api = `${setUri}&home=${(parseInt(payload.home)!==0)?payload.home:CAT1}&rand=${Math.random()}`;
            
            fetch(url_api).then(
                data => (data.json()).then(
                    sets_data => {

                       //let cat71 = sets_data[0];//sets_data.filter((item) => { return item.category === 71 });
                        //let cat75 = sets_data[1];//sets_data.filter((item) => { return item.category === 75 });
                        //let cat140 = sets_data[2];//sets_data.filter((item) => { return item.category === 140 });
                        //let cat160 = sets_data[3];//sets_data.filter((item) => { return item.category === 160 });

                        let categories=[]
                        for(let i=0;i<sets_data.length;i++){
                            categories[i]=sets_data[i];
                        }

                        if (payload.reGenerate !== undefined) {
                            dispatch(getpreLoadCorrds({ zoommState: payload.zoommState, scale: payload.scale, sets_data: categories, home: (parseInt(payload.home)!==0)?payload.home:CAT1, reGenerate: payload.reGenerate }));
                        } else {
                            dispatch(getpreLoadCorrds({ zoommState: payload.zoommState, scale: payload.scale, sets_data: categories, home: (parseInt(payload.home)!==0)?payload.home:CAT1 }));
                        }

                        //dispatch(setsSiteController({ sets_data }));
                        dispatch(setsSiteController({ sets_data: categories }));
                        //dispatch(setNumPage({ page: 1 }));
                    }
                )
            )
        }
    }
}
/*const getSetsOtherSetsConfiguration = payload => {
    return dispatch => {

         const url_api = `${setUri}&home=${payload.home}`;
         fetch(url_api).then(
            data => (data.json()).then(
                sets_data => {
                    let cat71 = sets_data.filter((item) => { return item.category === 71 });
                    let cat75 = sets_data.filter((item) => { return item.category === 75 });
                    let cat140 = sets_data.filter((item) => { return item.category === 140 });
                    let cat160 = sets_data.filter((item) => { return item.category === 160 });

                    dispatch(setsSiteController({ sets_data: [cat71, cat75, cat140, cat160] }));
                    dispatch(setNumPage({ page: 1 }));
                }
            )
        )
    }
}*/
///////////////////////////////////////////////////////////////////////////
//MODEL RELATED SCENES
///////////////////////////////////////////////////////////////////////////
const setModelScenes = payload => ({ type: SET_MODELSCENES, payload })
//const modelSceneUri = "https://newworlddemo.com/demo/relatedScenes_v2.php?" ;
const modelSceneUri = `${ADMINFILES}relatedScenes_v2.php?`;

export const getModelScenes = payload => {
    return dispatch => {
        dispatch(setloaderTransition({ IsLoading: true }));
        const url_api = `${modelSceneUri}&action=related&id=${payload.VideoID}&rand=${Math.random()}`;

        fetch(url_api).then(
            data => (data.json()).then(
                related_scenes => {
                    let content = [];

                    let setItems = [];
                    //related_scenes

                    dispatch(setModelScenes({ related_scenes }));
                    related_scenes.map((setItem, i) => {
                        if (i === 0) {
                            content = setItem.content;

                        }
                        setItems['holder' + i] = setItem;

                        return "done";
                    })
                    // dispatch(getVideoItem({ vidID: payload.VideoID }))
                    // let isPhoto=false;
                    let photArray = [];
                    let videoArray = [];
                    content.PLAY.map((item) => {

                        if (item['MediaType'] === 'video' && item['Quality'] === '2000kb') {

                            videoArray.push(item)
                        }

                        return item;
                    })
                    content.GALLERY.map((item) => {

                        if (item['MediaType'] === 'PHOTO') {

                            photArray.push(item)
                        }
                        return item;
                    }
                    )
                    //if(isPhoto){

                    dispatch(videoloaded({ Item: [photArray, videoArray, content.MODELS] }));
                    dispatch(setItmesContainer(setItems));
                    dispatch(setloaderTransition({ IsLoading: false }));
                    dispatch(setModalStatus({ ModalStatus: true }))

                }
            )
        )
    }
}
export const openFavorite = payload => {
    return dispatch => {
        let content = [];
        let setItems = [];

        payload.favorite_scenes.map((setItem, i) => {
            if (setItem.Id === payload.Id) {
                content = setItem.content;

            }
            setItems['holder' + i] = setItem;

            return "done";
        })

        let photArray = [];
        let videoArray = [];
        content.PLAY.map((item) => {

            if (item['MediaType'] === 'video' && item['Quality'] === '2000kb') {

                videoArray.push(item)
            }

            return item;
        })
        content.GALLERY.map((item) => {

            if (item['MediaType'] === 'PHOTO') {

                photArray.push(item)
            }
            return item;
        }
        )
        //if(isPhoto){

        dispatch(videoloaded({ Item: [photArray, videoArray, content.MODELS] }));
        dispatch(setItmesContainer(setItems));
        
    }
}
export const getFavorites = payload => {
    return dispatch => {
        dispatch(setloaderTransition({ IsLoading: true }));
        const url_api = `${modelSceneUri}&action=favorites&user=${payload.UserName}&rand=${Math.random()}`;

        fetch(url_api).then(
            data => (data.json()).then(
                favorite_scenes => {
                    dispatch(getFavoriteScenes({ favorite_scenes }));
                    dispatch(setloaderTransition({ IsLoading: false }));
                    dispatch(setModalStatus({ ModalStatus: true }))
                }
            )
        )
    }
}
/////////////////////////////LOAD VIDEO BY ID//////////////////////////////////////
const videoloaded = payload => ({ type: SET_VIDEOLOADED, payload })
export const getVideoItem = payload => {

    return dispatch => {


        // let isPhoto=false;
        let photArray = [];
        let videoArray = [];
        payload.ItemVid.PLAY.map((item) => {

            if (item['MediaType'] === 'video' && item['Quality'] === '2000kb') {

                videoArray.push(item)
            }

            return item;
        })
        payload.ItemVid.GALLERY.map((item) => {

            if (item['MediaType'] === 'PHOTO') {

                photArray.push(item)
            }
            return item;
        }
        )
        //if(isPhoto){

        dispatch(videoloaded({ Item: [photArray, videoArray, payload.ItemVid.MODELS] }));
        dispatch(setloaderTransition({ IsLoading: false }));
        dispatch(setModalStatus({ ModalStatus: true }))
        // }

    }
}

//////////////////////////LOAD COORDS//////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
//SetFavouriteScene SCENES
///////////////////////////////////////////////////////////////////////////

//const SceneUri = "https://newworlddemo.com/demo/relatedScenes.php?";
const SceneUri = `${ADMINFILES}relatedScenes_v2.php?`
export const SetFavouriteScene = payload => {
    return dispatch => {
        const url_api = `${SceneUri}&action=favorite&id=${payload.ID}&rand=${Math.random()}`;

        fetch(url_api).then(
            data => (data.json()).then(
                favscenes => {

                     let sets_data = [];
                     sets_data.push(payload.sets_data.map((item, i) => {
                       
                        if (item.Id === payload.ID) {
                            item.isfav = favscenes;
                       
                        }
                        return item;
                    }))
                    payload.sets_data=sets_data
                }
            )
        )
    }
}
export const UnsetFavouriteScene = payload => {
    return dispatch => {
        const url_api = `${SceneUri}&action=unfavorite&id=${payload.ID}`;

        fetch(url_api).then(
            data => (data.json()).then(
                favscenes => {

                    let sets_data = [];

                    sets_data.push(payload.sets_data.map((item, i) => {
                        if (item.Id === payload.ID) {
                            item.isfav = [];

                        }
                        return item;
                    }))
                    payload.sets_data=sets_data
                }
            )
        )
    }
}
export const UnsetFavouritem = payload => {
    return dispatch => {
        const url_api = `${SceneUri}&action=unfavorite&id=${payload.ID}`;
        let nfavorite_scenes=[];
        let todelete=0;
        console.log( payload.favorite_scenes);
        fetch(url_api).then(
            data => (data.json()).then(
                favscenes => {

                    //let sets_data = [];

                    payload.favorite_scenes.map((item, i) => {
                        if (item.isfav[0].Id !== payload.ID) {
                            nfavorite_scenes.push(item) ;
                           
                        }else{
                            todelete=item.Id
                        }
                        return item;
                    })
                    dispatch(DeleteFavoriteScenes({ Id: todelete }));
                   // dispatch(setModalStatus({ ModalStatus: true }))
                }
            )
        )
    }
}
export const _LoadModels = payload => {
    return dispatch => {
        //dispatch(setloaderTransition({ IsLoading: true }));
        const url_api = `${modelSceneUri}&action=models&rand=${Math.random()}`;

        fetch(url_api).then(
            data => (data.json()).then(
                models => {
                    dispatch(loadModels({ source:models }));
                    
                }
            )
        )
    }
}