import React from 'react';
import { Provider } from 'react-redux';
import { Route,   Switch,BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import PropTypes from 'prop-types';

import App from './App';
/*import HomepageLayout from './pages/HomepageLayout';
import HomePage from './pages/HomePage';
import ModelPage  from './pages/ModelPage';
import VideoPage  from './pages/VideoPage';
import DashBoard from './pages/DashBoard';*/



//import { createBrowserHistory } from 'history';
//history={createBrowserHistory()}

const Root = ({ store,persistor }) => (
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter basename="/" >
    <Switch>
        <Route exact path="/" component={App} /> 
       {/* <Route path="/(:filter)"  component={HomepageLayout} />
        <Route path="/HomepageLayout" component={HomepageLayout} />
        <Route exact path="/ModelPage/:id" component={ModelPage}  />
        <Route path="/HomePage" component={HomePage} />
        <Route path="/DashBoard" component={DashBoard} />
        <Route path="/VideoPage" component={VideoPage} /> */}
    </Switch>
    </BrowserRouter>
    </PersistGate>

  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;