import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
//import { getPopcontent } from './ModalInfo'
import { setModalStatus, SetFavouriteScene, UnsetFavouriteScene, getModelScenes, } from './../../actions'
import Paper from '@material-ui/core/Paper';
import VideoSeeApp from './../video/VideoSeeApp'
import ItemsContainer from './../itemsContainer'
import CalltoAction from './../itemsContainer/CalltoAction'
import { isMobile, isTablet } from 'react-device-detect';
import CircularProgress from '@material-ui/core/CircularProgress'
import { Modal, Grid, Header, Label, Segment, Card, Icon, Transition } from 'semantic-ui-react'

import { ClassCAT1, ClassCAT2, ClassCAT3, ClassCAT4, ColorCAT1, ColorCAT2, ColorCAT3, ColorCAT4 } from './../../constants/constants';
import LazyImage from './../itemsContainer/LazyImage'

class PopupTriggerModal extends Component {
    contextRef = createRef()
    constructor(props) {
        super(props);
        this.state = {
            openthis: this.props.openthis,
            viewdisp: 'none',
            imageLoded: false,
            modelId: 0,
            _related_scenes: [],
            _item: [],
            showEffect: false,
            readMore:true,
            txtrMore:'Read More',
            icon:'plus'
        }
    }
    toggleText=()=> {
        document.querySelector('.descrip').classList.toggle("truncate");
        let estadoac=this.state.readMore;
        this.setState({icon:(this.state.readMore)?'minus':'plus',txtrMore:(!this.state.readMore)?'Read More':'Read Less',readMore:(estadoac)?false:true})
    }
    
    componentDidMount() {
        this.setState({ showEffect: true })
    }
    renderProgress = () => {
        return <CircularProgress color="secondary" size={50} />;
    }
    setModalOpen = (v) => {

        this.setState({ openthis: true })

        this.props.getModelScenes({ VideoID: v })
        const { related_scenes } = this.props;
        if (related_scenes) {
            related_scenes.map((item, i) => { if (i === 0) { this.setState({ _item: item }); } return null; })
            this.setState({ _related_scenes: related_scenes })
        }
        //let modalItem = related_scenes ? related_scenes.map( (item,i) =>{ if(i===0){ console.log(item);this.setState({ _item:item}) ;} return null;}) : null; 
        //modalItem=related_scenes ? this.setState({ _related_scenes:related_scenes}) : ''; 

    }
    setModalClose = () => {
        this.setState({ openthis: false })
        this.props.setModalStatus({ ModalStatus: false });

    }
    unfavourite = (isFav, Id) => {
        if (isFav.length > 0) {

            this.props.UnsetFavouriteScene({ sets_data: this.props.related_scenes, ID: Id })

        }
    }
    favourite = (Id) => {

        this.props.SetFavouriteScene({ sets_data: this.props.related_scenes, ID: Id })
    }
    setIsImageLoaded = (v) => {
        this.setState({ imageLoded: v });
    }
    render() {
        // let image;
        let clsName = '';
        const { related_scenes, setItem, itemtoload } = this.props;
        let modalItem = this.state._item;
        const { zoommState } = this.props.zoomState;

        // image, Id, models, setdescription, title, clsName, previewvid, previewvidposter, placeHolder
        if (setItem.lasttendays === 'YES') {
            clsName = 'latest';
        } else {
            clsName = setItem.colourCat;
        }

        /* if (setItem !== '') {
             if (isMobile) {
 
                 if (zoommState === 'zoom12') {
                     image = setItem.Thumb3;
                 } else {
                     if (zoommState === 'zoom5') {
                         image = setItem.Thumb5;
                     } else {
                         image = setItem.Thumb3;
                     }
                 }
             } else {
                 image = setItem.ShowedImage;
                
             }
             image=SITEURL+""+setItem.Directory+""+image;
         }*/
        //let img = SITEURL+""+setItem.Directory+"/3.jpg";
        let color;
        if (setItem.colourCat === ClassCAT1) {
            color = ColorCAT1;
        }
        if (setItem.colourCat === ClassCAT2) {
            color = ColorCAT2;
        }
        if (setItem.colourCat === ClassCAT3) {
            color = ColorCAT3;
        }
        if (setItem.colourCat === ClassCAT4) {
            color = ColorCAT4;
        }
        let Style = {
            fontSize: '0.50rem',

        }
        return (
            <div>
                <Label as='a' color={color} corner={true} onClick={() => this.setModalOpen(setItem.Id)} style={Style}>
                    <Icon name='search plus' size='large' className={this.props.rideValue && setItem.Id===this.props.rideValue.Id?'my-first-step':''} />
                </Label>
                <Modal
                    key={`modal_${setItem.Id}`}
                    open={(this.state.openthis && this.props.ModalStatus) ? true : false}
                    closeOnDocumentClick={true}
                    position='top center'
                    closeIcon

                    size="large"


                    onClose={this.setModalClose}
                    trigger={
                        <Paper elevation={6} className={clsName} square={true} style={{ background: 'none', borderRadius: 0 }}  >

                            {(this.props.zoomstate === 'zoom6x' || this.props.zoomstate === 'zoom4x' || this.props.zoomstate === 'zoom12x') ?
                                <Label attached='bottom' className={clsName} size='big' style={{ zIndex: 999, textAlign: 'center' }} >{setItem.title}</Label>
                                : ''}

                            <Transition
                                animation={"jiggle"}
                                duration={900}
                                visible={this.state.showEffect} >
                                <CalltoAction eventClick={this.setModalOpen} Item={setItem} zoomstate={zoommState}>

                                    <LazyImage src={setItem} itemtoload={itemtoload} zoomState={zoommState} scale={this.props.levelZoom} cdntime={this.props.zoomState.cdntime} />
                                </CalltoAction>
                            </Transition>
                        </Paper>
                    }>

                    <Modal.Content>
                        {(related_scenes && this.props.itemsHolder && modalItem) ?
                            <Modal.Description >




                                <VideoSeeApp category={setItem.category} cdntime={this.props.zoomState.cdntime} handleClose={this.setModalClose} _favorite={this.favourite} _isFav={related_scenes[0].isfav} Id={related_scenes[0].Id} _unfavourite={this.unfavourite} key={related_scenes[0].previewvid} previewvid={related_scenes[0].previewvid} previewvidposter={related_scenes[0].previewvidposter} title={related_scenes[0].title} models={related_scenes[0].models} myItem={related_scenes[0]} />
                                {(related_scenes[0].description && related_scenes[0].description.length>10)?
                                <Segment inverted style={{ marginTop: '0', marginBottom: (!isMobile) ? '0' : '1rem' }} >
                                
                                    <Grid inverted={true} padded>
                                        <Grid.Column className="nopadding">
                                            <div className="descrip truncate">
                                                {(related_scenes[0].description) ? related_scenes[0].description : ''}
                                                
                                                
                                            </div>
                                            {(related_scenes[0].description && related_scenes[0].description.length>100)? <span  onClick={this.toggleText} style={{  cursor:'pointer'}}>{this.state.txtrMore}<Icon name={this.state.icon +' square outline'}  className="readMore"size='small' style={{ paddingLeft: '10px', cursor:'pointer'}} /></span>:''}

                                        </Grid.Column>
                                    </Grid>

                                </Segment>:''}

                                <Segment inverted style={{ marginBottom: '0', marginTop: '0', borderRadius: 0 }} >
                                    <Grid columns={1} divided className="nomargin" >
                                        <Grid.Column>
                                            <Header inverted className="nomargin" >More like this:</Header>
                                            <Card.Group style={{ margin: '0' }} itemsPerRow={(isMobile) ? (isTablet) ? '3' : '2' : '4'} >
                                                {(related_scenes && this.props.itemsHolder) ? related_scenes.map((item, i) => {
                                                    let ritem = '';
                                                    if (i > 0) {
                                                        ritem = <ItemsContainer key={`scene${i}`} set={item} handleClose={this.setModalClose} cdntime={this.props.zoomState.cdntime} />
                                                    }
                                                    return ritem;
                                                }) : this.renderProgress()}
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>

                            </Modal.Description> : ''}
                    </Modal.Content>
                </Modal></div>)
    }

}
const mapSetsToProps = state => ({
    UserConfiguration: state.setUserConfiguration
    , sets_data: state.setsSiteController.sets_data
    , itemsHolder: state.setItmesContainer
    , related_scenes: state.setModelScenes.related_scenes
    , ModalStatus: state.setModalStatus.ModalStatus
    , levelZoom: state.levelZoom.levelZoom
    , zoomState: state.zoomState
    , rideValue: state.rideManager.value
});
const mapDispatchToPropsActions = dispatch => ({
    setModalStatus: value => dispatch(setModalStatus(value)),
    SetFavouriteScene: value => dispatch(SetFavouriteScene(value)),
    UnsetFavouriteScene: value => dispatch(UnsetFavouriteScene(value)),
    getModelScenes: value => dispatch(getModelScenes(value)),


});
export default connect(mapSetsToProps, mapDispatchToPropsActions)(PopupTriggerModal);

