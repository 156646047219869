import { SET_LOADERMORE } from '../actions'


export const setLoadingMore = (state=null, action) => {
    
    switch (action.type) {
        case SET_LOADERMORE:
          
         return {...state,
            letload:action.payload.letload,
            }
      
        default:
           return {...state}
           
    }
  //  return state;
}