import React, { Component, createRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { setModalStatus } from '../../actions'
import { SITEKEY, VERSION, MAILURL, PASSWORDRETRIVE, SID } from './../../constants/constants'
import { isMobile } from 'react-device-detect';
//import FavoriteContainer from '../itemsContainer/FavoriteContainer'


//import { isMobile,isTablet } from 'react-device-detect';
import CircularProgress from '@material-ui/core/CircularProgress'
import { Modal, Header, Segment, Grid, Form, Button,Message } from 'semantic-ui-react'


class ContactUs extends Component {
  contextRef = createRef()
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      pass_name: '',
      pass_email: '',
      message: '',
      callback: "not fired",
      value: null,
      load: false,
      expired: "false",
      msg: '',
      msgContact: '',formError:0,formContError:0

    }
    this._reCaptchaRef = React.createRef();

  }
  //----------------gOOGLE CAPCHA---------------------
  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, 1500);

  }

  handleChange = value => {

    this.setState({ value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });

  };
  ////////////////////////////////////////////////////

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!  
    console.log(recaptchaToken, "<= your recaptcha token")
  }
  renderProgress = () => {
    return <CircularProgress color="secondary" size={50} />;
  }
  handleSubmitPass = (e) => {
    e.preventDefault();
    try {
      axios({
        method: "GET",
        url: `${PASSWORDRETRIVE}?siteid=${SID}&submit=1&email=${this.state.pass_email}&username=${this.state.pass_name}`,

      }).then(
        (error) => { alert("Network Error, Please Try agin, Thank you!");console.log(error) },
        (response) => {
        if (response.data.length < 206) {
          this.setState({ msg: "Your password has been emailed to you", formError:1});
          setTimeout(this.resetMSN(), 3000);
        } else {


          this.setState({ msg: "Sorry, we could not locate you, please contact us using the form below" , formError:2});
          setTimeout(this.resetMSN(), 3000);
        }
      }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    } catch (err) {
      console.log(err.toJSON());
      alert("Network Error, Please Try agin, Thank you!");
    }

  }
  handleSubmit(e) {
    if (this.state.value !== null) {
      e.preventDefault();
      try {
        axios({
          method: "GET",
          url: `${MAILURL}template-contactform-name=${this.state.name}&template-contactform-email=${this.state.email}&template-contactform-message=${this.state.message}&IP=${this.props.UserConfiguration.IP}&version=${VERSION}`,

        }).then((response) => {
          if (response.data.alert === 'success') {
            this.setState({ msgContact: response.data.message , formContError:1});
            setTimeout(this.resetMSN(), 3000);
            this.resetForm()
          } else if (response.data.alert === 'error') {
            this.setState({ msgContact: response.data.message, formContError:2 });
            setTimeout(this.resetMSN(), 3000);
          }
        }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
      } catch (e) {
        alert("Network Error, Please Try agin, Thank you!");
      }
    } else {
      alert("Please Check Google Captcha");
    }
  }
  resetMSN = () => {
    this.setState({ msgContact: '', msg: '',formError:0 });
  }
  onPassNameChange(event) {
    this.setState({ pass_name: event.target.value })
  }

  onPassEmailChange(event) {
    this.setState({ pass_email: event.target.value })
  }
  resetPassForm() {
    this.setState({ pass_name: '', pass_email: '',formError:0 })
  }
  onNameChange(event) {
    this.setState({ name: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }
  onEmailCheck(event) {
    this.setState({ email: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }
  resetForm() {
    this.setState({ name: '', email: '', message: '' ,formError:0})
  }


  render() {
    const { load } = this.state || {};
    //const {err} = this.props;
    return (
      <div>

        <Modal
          key={`modal_favorite`}
          open={(this.props.opneContactUs && this.props.ModalStatus) ? true : false}
          closeOnDocumentClick={true}
          position='top center'
          closeIcon
          size="large"
          onClose={this.props.setContactModalClose}
        >

          <Modal.Content >

            <Modal.Description >

              <Segment inverted style={{ marginBottom: '0', marginTop: '0', borderRadius: 0 }} >
                <Grid inverted centered padded divided='vertically'>
                  <Grid.Row centered>
                    <Grid.Column  width={(isMobile) ? '14' : '12'}  className="bg-contact">
                      <Header inverted textAlign='center' className="nomargin" >Forgotten Your Password?</Header>
                      
                      <Form id="cancel-form" onSubmit={this.handleSubmitPass.bind(this)} method="POST" 
                            success= {this.state.formError===0 || this.state.formError===2 ? false : true}
                            error= {this.state.formError===0 || this.state.formError===1 ? false : true} inverted > 
                      
                        <Form.Input label='Enter your username here' placeholder='Username' width={16} className="form-control" id="name" value={this.state.pass_name}
                          onChange={this.onPassNameChange.bind(this)} required={true} />
                        <Form.Input label='Email address' placeholder='Email address' type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.pass_email}
                          onChange={this.onPassEmailChange.bind(this)} required={true} />
                        <Button size="medium" type="submit" fluid color="teal" >Submit</Button>
                        {this.state.msg !== '' ? 
                          <Message
                          success= {this.state.formError===0 || this.state.formError===2 ? false : true}
                            error= {this.state.formError===0 || this.state.formError===1 ? false : true}
                          header='Form Completed'
                          content={this.state.msg}
                        />
                          : ''}
                      </Form>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row >
                    <Grid.Column  width={(isMobile) ? '14' : '12'}  className="bg-contact">
                      <Header textAlign='center' inverted className="nomargin" >Contact Us:</Header>


                      <Form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" inverted 
                      success= {this.state.formContError===0 || this.state.formContError===2 ? false : true}
                      error= {this.state.formContError===0 || this.state.formContError===1 ? false : true}
                      >
                        {this.state.msgContact !== '' ? 
                        <Message
                        success= {this.state.formContError===0 || this.state.formContError===2 ? false : true}
                        error= {this.state.formContError===0 || this.state.formContError===1 ? false : true}
                          header='Form Error'
                          content={this.state.msgContact}
                        /> : ''}


                        <Form.Input label='First Name' placeholder='First Name' width={16} className="form-control" id="name" value={this.state.name}
                          onChange={this.onNameChange.bind(this)} required={true} />

                        <Form.Input label='Email address' placeholder='Email address' type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email}
                          onChange={this.onEmailChange.bind(this)} required={true} />



                        <Form.TextArea placeholder='Message' label='Message' className="form-control" id="message" value={this.state.message}
                          onChange={this.onMessageChange.bind(this)} required={true} />
                        <div className="form-group">
                          {load && (
                            <ReCAPTCHA
                              style={{ display: "inline-block" }}
                              theme="dark"
                              ref={this._reCaptchaRef}
                              sitekey={SITEKEY}
                              onChange={this.handleChange}
                              asyncScriptOnLoad={this.asyncScriptOnLoad}
                            />
                          )}
                        </div>

                        <Button fluid size="medium" type="submit" color="teal">Submit</Button>
                      </Form>



                    </Grid.Column>
                  </Grid.Row>

                </Grid>
              </Segment>




            </Modal.Description>
          </Modal.Content>
        </Modal></div>)
  }

}
const mapSetsToProps = state => ({
  UserConfiguration: state.setUserConfiguration
  , ModalStatus: state.setModalStatus.ModalStatus
});
const mapDispatchToPropsActions = dispatch => ({
  setModalStatus: value => dispatch(setModalStatus(value)),




});
export default connect(mapSetsToProps, mapDispatchToPropsActions)(ContactUs);


