import { SET_LOADERTRANSITION } from '../actions'


export const loaderTransition = (state=null, action) => {
    
    switch (action.type) {
        case SET_LOADERTRANSITION:
          
         return {...state,
                    IsLoading:action.payload.IsLoading,
                    
             }
      
        default:
           return {...state}
           
    }
  //  return state; 
}