
import { SET_ZOOMSTATUS } from './../actions'
export const zoomState = (state=null, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case SET_ZOOMSTATUS:
         return {...state,
                panningBlock:action.payload.panningBlock,
                value: action.payload.value,
                zoommState:action.payload.zoommState,
                numoffSets:action.payload.numoffSets,
                panningendX: action.payload.panningendX,
                panningendY:action.payload.panningendY,
                xstarton: action.payload.xstarton,
                xsEndon: action.payload.xsEndon,
                ystarton: action.payload.ystarton,
                yEndon: action.payload.yEndon,
                procentaje: action.payload.procentaje,
                ultimaxr: action.payload.ultimaxr,
                ultimayr: action.payload.ultimayr,
                ultimaxl: action.payload.ultimaxl, 
                ultimayl: action.payload.ultimayl, 
                ultimaxd: action.payload.ultimaxd, 
                ultimayd: action.payload.ultimayd, 
                ultimaxt: action.payload.ultimaxt, 
                ultimayt: action.payload.ultimayt,
                xDir: action.payload.xDir,
                yDir: action.payload.yDir,
                currentW: action.payload.currentW,
                currentH: action.payload.currentH,
                distancia: action.payload.distancia,
                distanciaY: action.payload.distanciaY,
                itemsStyles: action.payload.itemsStyles,
                zoomCantX: action.payload.zoomCantX,
                zoomCantY: action.payload.zoomCantY,
                restoreview: action.payload.restoreview,
                page:action.payload.page,
                lockAxisX:action.payload.lockAxisX,
                lockAxisY:action.payload.lockAxisY,
            }
      
        default:
           return state
           
    }
  //  return state;
}
