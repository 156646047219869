

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';
import { setModalStatus,cleanSearch, startSearch, updateSelection, finishSearch, _LoadModels,SetFavouriteScene,UnsetFavouriteScene } from './../../actions'
import { Modal, Grid, Header, Segment,   Card } from 'semantic-ui-react'

import VideoSeeApp from './../video/VideoSeeApp'

import ItemsContainer from './../itemsContainer'




class ModalSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openVideoModal: false,
            vid_gal: 0,
            size_modal: 0,
            modelsSelected: 0,
            openthis:false,
           

        }
    }
    
    
    unfavourite = (isFav,Id) => {
        if (isFav.length >0) {
            
            this.props.UnsetFavouriteScene({ sets_data: this.props.related_scenes, ID: Id })

        }
    }
    favourite = (Id) => {

        this.props.SetFavouriteScene({ sets_data: this.props.related_scenes, ID: Id })
    }

    
   
 
  
    render() {


        
        let  related_scenes = this.props.related_scenes ? this.props.related_scenes : []
        
     



        return (
            
                    <Modal
                        open={(this.props.openthis && this.props.ModalStatus) ? true : false}
                        closeOnDocumentClick={true}
                        position='top center'
                        closeIcon
                        size="large"
                        onClose={this.props.setModalClose}
                    >

                        <Modal.Content >
                        {(related_scenes && this.props.itemsHolder && related_scenes.length>0 ) ?
                            <Modal.Description >
                                <VideoSeeApp cdntime={this.props.zoomState.cdntime} handleClose={this.setModalClose} _favorite={this.favourite} _isFav={related_scenes[0].isfav} Id={related_scenes[0].Id} _unfavourite={this.unfavourite} key={related_scenes[0].previewvid} previewvid={related_scenes[0].previewvid} previewvidposter={related_scenes[0].previewvidposter} title={related_scenes[0].title} models={related_scenes[0].models} myItem={related_scenes[0]} />

                                <Segment inverted style={{ marginTop: '0', marginBottom: (!isMobile) ? '0' : '1rem' }} >

                                    <Grid inverted={true} padded>
                                        <Grid.Column className="nopadding">
                                           {(related_scenes[0].description) ? related_scenes[0].description : ''}

                                        </Grid.Column>
                                    </Grid>

                                </Segment>

                                <Segment inverted style={{ marginBottom: '0', marginTop: '0', borderRadius: 0 }} >
                                    <Grid >
                                        <Header style={{width:'100%'}} inverted className="nomargin" >More like this:</Header>
                                        <Card.Group style={{ margin: '0' }} itemsPerRow={(isMobile) ? (isTablet) ? '3' : '2' :  ((related_scenes ) && (related_scenes.length>2 ))?'4':'2'} >
                                            {(related_scenes && this.props.itemsHolder) ? related_scenes.map((item, i) => {
                                                let ritem = '';
                                                if (i > 0) {
                                                    ritem = <ItemsContainer key={`scene${i}`} set={item} handleClose={this.setModalClose} cdntime={this.props.zoomState.cdntime} />
                                                }
                                                return ritem;
                                            }) : this.renderProgress()}
                                        </Card.Group>

                                    </Grid>
                                </Segment>

                            </Modal.Description>:''}
                    </Modal.Content>
                    </Modal>
              
        )
    }
}
const mapSetsToProps = state => ({
    searchstate: state.searchManager,
    source: state.models.source,
    zoomState: state.zoomState,
    related_scenes: state.setModelScenes.related_scenes,
    itemsHolder: state.setItmesContainer,
    ModalStatus: state.setModalStatus.ModalStatus
});
const mapDispatchToPropsActions = dispatch => ({
    cleanSearch: value => dispatch(cleanSearch(value)),
    startSearch: value => dispatch(startSearch(value)),
    updateSelection: value => dispatch(updateSelection(value)),
    finishSearch: value => dispatch(finishSearch(value)),
    _LoadModels: value => dispatch(_LoadModels(value)),
    setModalStatus: value => dispatch(setModalStatus(value)),
    SetFavouriteScene: value => dispatch(SetFavouriteScene(value)),
    UnsetFavouriteScene: value => dispatch(UnsetFavouriteScene(value)),


});
export default connect(mapSetsToProps, mapDispatchToPropsActions)(ModalSearch);

