import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Joyride from 'react-joyride';
import Cookies from 'universal-cookie';
import { isMobile } from 'react-device-detect';
import { Loader, Dimmer, Header,Icon,Image } from 'semantic-ui-react'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { PRCTOWHIDTH, INITPRCTOWHIDTH, DEFPCZOOM, DEFMOBZOOM,ZOOMPC,ZOOMMOBILE } from './constants/constants';

import { setZoomStatus, setSetStyles, getUserConfiguration, getpreLoadCorrds, _CoordsToSets, _CoordsToSetsResetImg, getpreNewLoadCorrds, _GenerateMoreCoords } from './actions'


import { ResponsiveContainer } from './components/theme/ResponsiveContainer';


import LoaderItemsbyCoords from './components/LoaderItemsbyCoords';

import ModalDefaultHome from './components/helpers/ModalDefaultHome';


import { Link } from 'react-router-dom';

import { checktogenerate } from './services/coordGenerator'

import CircularProgress from '@material-ui/core/CircularProgress'
import { getPorce, } from './services/functions'

import logo from './components/holder/logo.png'
import './App.css';

const LoadingDimmer = ({ estado }) => (
  <Dimmer active={estado} page>
    <Header as='h2' icon inverted>
      <Loader>Loading</Loader>
    </Header>
  </Dimmer>

)
const cookies = new Cookies();
class App extends Component {
  constructor(props) {
    super(props);
    // this.handleMouseClick = this.handleMouseClick.bind(this);


    this.state = {
      global: 0, // lo que avanzo en x + 
      globaly: 0,
      globalnx: 0,
      globalyny: 0,
      isGenerating:false,
      x: 0, y: 0,
      scale: (!isMobile) ? ZOOMPC : ZOOMMOBILE ,
      changeOritentation: false,
      defaultPositionX: window.innerWidth / window.innerWidth - 60,
      defaultPositionY: 0,// window.innerHeight / window.innerHeight,
      ZoomInOut: 0,
      type: true,
      limitToBounds: false,
      panningEnabled: true,
      transformEnabled: true,
      pinchEnabled: true,
      limitToWrapper: false,
      disabled: false,
      dbClickEnabled: false,
      lockAxisX: false,
      lockAxisY: false,
      velocityEqualToMove: true,
      enableWheel: true,
      enableTouchPadPinch: true,
      enableVelocity: true,
      limitsOnWheel: false,
      zoommState: (isMobile) ? (window.innerWidth < 900)? DEFMOBZOOM : DEFPCZOOM :  DEFPCZOOM ,
      openModalHomeDefault: false,
      showride:true,
      steps: [
        {
            content: <h2>Let's begin our Tour!</h2>,
            locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
            placement: 'center',
            target: 'body',
            showSkipButton :false,
            title: <Image centered size='small' alt="World Of Men" src={logo} />
          },
          {
            content: (
                <div>
                  Click the <Icon name='search plus' size='large'   />
                  <br />
                  to view the scene
                </div>
              ),
              disableBeacon: true,
              disableOverlayClose: false,
              hideCloseButton: false,
             
              placement: 'bottom',
              spotlightClicks: true,
              styles: {
                options: {
                  zIndex: 10000,
                },
              },   
            target: '.my-first-step',
            title: 'View Scenes',  
           
          },
          {
            content: 'Choose the kind of guys you like here',
            disableBeacon: true,
            placement: 'right',
            spotlightPadding: 0,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: '.Menu-class-second',
            title: 'Menu',  
           
          },
          {
            content: 'Move in any direction using your fingers or click and drag the mouse',
            disableBeacon: true,
            placement: 'center',
            spotlightPadding: 0,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: 'body',
            title: 'Move in any Direction',  
           
          },
         
          {
            content: 'Zoom using your fingers or mouse',
            disableBeacon: true,
            placement: 'center',
            spotlightPadding: 0,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: 'body',
            title: 'Zoom',  
           
          }
        ],
    };
    //this.props.getpreLoadCorrds({ zoommState: this.state.zoommState,scale:this.state.scale });

    //this.handleitems(0)
    console.log((!isMobile) ? ZOOMPC : ZOOMMOBILE);
  }
  componentDidMount() {
   
    this.mycomponentDidMount();
    this.props.getUserConfiguration({ action: 'load', zoommState: this.state.zoommState, scale: this.state.scale });
    
    window.addEventListener('resize', this.handleResize);
    if( cookies.get('endRideMember')!==undefined){
      this.setState({showride:false})
    }else{
      if(!this.props.UserConfiguration.OpenModalHome){
         cookies.set('endRideMember', 'NomoreRide', { path: '/' });
      }else{
        
        cookies.set('endRideMember', undefined, { path: '/' });
      }
    }
  }
  handleMouseClick(event) {
    this.setState({
      x: event.clientX,
      y: event.clientY
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (!this.props.ModalStatus) {
      //console.log("resize event forse reload");
      this.mywindowsresie();
      this.setState({ changeOritentation: false });
      //window.location.reload();
    } else {
      this.setState({ changeOritentation: true });
    }
  };
  mywindowsresie = () => {

    let MYscreenwidth = Math.round(parseInt(window.innerWidth * getPorce(this.state.zoommState)));
    let myHeight = MYscreenwidth / ((getPorce(this.state.zoommState) === 0.5) ? INITPRCTOWHIDTH : PRCTOWHIDTH);
    
    this.props.setSetStyles({
      ...this.props, currentW: window.innerWidth * getPorce(this.state.zoommState), currentH: myHeight,
      restoreview:true
      , zoommState: (isMobile) ? (window.innerWidth<900)? DEFMOBZOOM : DEFPCZOOM  :DEFPCZOOM,

    });
    
    this.setState({ zoommState: (isMobile) ? (window.innerWidth<900)? DEFMOBZOOM : DEFPCZOOM  :  DEFPCZOOM, restoreview: true , globaly: 0, global: 0, globalyny: 0, globalnx: 0 });
    
  //    this.props._CoordsToSets({  zoommState: this.props.zoomState.zoommState, scale: this.state.scale, coords: this.props.coords, positionX: 0, positionY: 0, alreadyAdded: this.props.Items, UsedCoords: this.props.UsedCoords, sets_data: this.props.sets_data, Home: this.props.UserConfiguration.Home, })
  let scale=ZOOMPC;
  if(isMobile){
       scale=ZOOMMOBILE;
       
  }      
    this.props.getpreNewLoadCorrds({ clickX: this.state.x, clickY: this.state.x, zoommState: (isMobile) ? (window.innerWidth<900)? DEFMOBZOOM:DEFPCZOOM  : DEFPCZOOM, scale: this.state.scale, coords: this.props.coords, positionX: 0, positionY: 0, alreadyAdded: this.props.Items, UsedCoords: this.props.UsedCoords, sets_data: this.props.sets_data, Home: this.props.UserConfiguration.Home });
    this.handleWheelEnd({scale: scale, positionX: this.state.x, positionY: this.state.y})
  }
  

  mycomponentDidMount() {
    let MYscreenwidth = Math.round(parseInt(window.innerWidth * getPorce(this.state.zoommState)));
    let myHeight = MYscreenwidth / ((getPorce(this.state.zoommState) === 0.5) ? INITPRCTOWHIDTH : PRCTOWHIDTH);


    this.props.setSetStyles({
      ...this.props,
      currentW: window.innerWidth * getPorce(this.state.zoommState),
      currentH: myHeight,
      value: 0,
      zoommState: this.state.zoommState,

    });

  }
  FilterLink = (filter, children) => (
    <Link to={filter}   >
      <Typography variant="subtitle1" color="inherit">{children} </Typography>
    </Link>
  );



  async generateMoreCoords() {
    this.setState({isGenerating:false});
    let myoldcords=this.props.coords;
    
    await this.props._GenerateMoreCoords({coords : myoldcords, zoommState: this.state.zoommState,});
  }

 //---------------------------------------------------------------------//
  handleWheelEnd = (e) => {
   
    let rg =false;
    rg = checktogenerate(e.positionX, e.positionY, this.props.coords, e.scale);
    this.setState({  globaly: 0, global: 0, globalyny: 0, globalnx: 0 })

    if (!this.state.isGenerating &&  rg) {
      this.setState({ isGenerating: true });
      this.generateMoreCoords();
    }

    
    this.props._CoordsToSetsResetImg({ scale: e.scale, levelZoom: this.props.levelZoom })
    if (this.props.zoomState.restoreview) {
      this.changeState(e);
    }
    const posX = parseInt(e.positionX);
    const posY = parseInt(e.positionY);
    this.setState({ x: e.positionX, y: e.positionY, })
    let willendscale = e.scale;
    if (e.scale <= 1) {
      willendscale = 1
    }
    this.setState({ scale: willendscale });
    
    
    this.props._CoordsToSets({ clickX: this.state.x, clickY: this.state.x, zoommState: this.props.zoomState.zoommState, scale: e.scale, coords: this.props.coords, positionX: posX, positionY: posY, alreadyAdded: this.props.Items, UsedCoords: this.props.UsedCoords, sets_data: this.props.sets_data, Home: this.props.UserConfiguration.Home })
    //e.scale=e.scale-0.02;
    //this.handleWheelEndtransform(e)

  };
  //---------------------------------------------------------------------//
  //---------------------------------------------------------------------//
  handleWheelEndtransform = (e) => {
    
    let rg=false;
    rg = checktogenerate(e.positionX, e.positionY, this.props.coords, e.scale);
    
    if (!this.state.isGenerating &&  rg) {
      this.setState({ isGenerating: true });
      this.generateMoreCoords();
      
    }
    if (e.scale > 1) {
      if (this.props.zoomState.restoreview) {
        this.changeState(e);
      }
      const posX = parseInt(e.positionX);
      const posY = parseInt(e.positionY); 
     // console.log("cell restoring");
      this.setState({ scale: e.scale,lobaly: 0, global: 0, globalyny: 0, globalnx: 0 },
        
        this.props._CoordsToSets({ clickX: this.state.x, clickY: this.state.x, zoommState: this.props.zoomState.zoommState, scale: e.scale, coords: this.props.coords, positionX: posX, positionY: posY, alreadyAdded: this.props.Items, UsedCoords: this.props.UsedCoords, sets_data: this.props.sets_data, Home: this.props.UserConfiguration.Home })
      )
    } else {
      if (e.scale <= 1) {
        const posX = parseInt(e.positionX);
        const posY = parseInt(e.positionY);
        this.setState({ scale: 1 })
        if (this.setState.scale === 1)
          this.props._CoordsToSets({ clickX: this.state.x, clickY: this.state.x, zoommState: this.props.zoomState.zoommState, scale: e.scale, coords: this.props.coords, positionX: posX, positionY: posY, alreadyAdded: this.props.Items, UsedCoords: this.props.UsedCoords, sets_data: this.props.sets_data, Home: this.props.UserConfiguration.Home })
      }
    }
  };
//---------------------------------------------------------------------//
 /* touchevent = (e) => {
    if (e.scale > 1) {
      if (this.props.zoomState.restoreview) {
        this.changeState(e);
      }
      const posX = parseInt(e.positionX);
      const posY = parseInt(e.positionY);
      this.setState({ scale: e.scale },
        this.props._CoordsToSets({ clickX: this.state.x, clickY: this.state.x, zoommState: this.props.zoomState.zoommState, scale: e.scale, coords: this.props.coords, positionX: posX, positionY: posY, alreadyAdded: this.props.Items, UsedCoords: this.props.UsedCoords, sets_data: this.props.sets_data, Home: this.props.UserConfiguration.Home })
      )
    } else {
      if (e.scale <= 1) {
        this.setState({ scale: 1 })
      }
    }
  }
 handleReset(e){
  this.handPanningStop({positionX:e.positionX,positionY:e.positionY})
 }*/
  //---------------------------------------------------------------------//

  changeState = (e) => {

    this.props.setSetStyles({
      zoommState: this.state.zoommState
      , currentW: window.innerWidth * getPorce(this.state.zoommState)
      , currentH: (window.innerWidth * getPorce(this.state.zoommState)) / ((getPorce(this.state.zoommState) === 0.5) ? INITPRCTOWHIDTH : PRCTOWHIDTH)
      , restoreview: false,

    })

  }
//---------------------------------------------------------------------//
//---------------------------------------------------------------------//
  handPanningStop = (e) => {
    let rg=false;
    rg = checktogenerate(e.positionX, e.positionY, this.props.coords, e.scale);
    
    if (!this.state.isGenerating &&  rg) {
      this.setState({ isGenerating: true });
     
      this.generateMoreCoords();
    
    }
    const posX = parseInt(e.positionX);
    const posY = parseInt(e.positionY);

    let loadmore = false;
    let loadmorey = false;

    let val = this.state.global;
    let valy = this.state.globaly;
    let valnx = this.state.globalnx;
    let valny = this.state.globalyny;


    if (posX < 0) {
      loadmore = (posX - this.state.global < -100) ? true : false;
      if(posX - this.state.global > 100){loadmore = true } ;
      val = parseInt(posX) * -1;
    }
    if (posX > 0) {
      loadmore = (posX - this.state.globalnx > 100) ? true : false;
      if(posX - this.state.globalnx < -100){ loadmore=true;}
      valnx = parseInt(posX);
    }

    if (posY < 0) {
      loadmorey = (posY - this.state.globaly < -100) ? true : false;
      if(posY - this.state.globaly > 100){ loadmorey =true;} ;
      valy = parseInt(posY) * -1;
    }

    if (posY > 0) {
      loadmorey = (posY - this.state.globalyny > 100) ? true : false;
      if (posY - this.state.globalyny < -100){loadmorey =true};
      valny = posY;
    }
   
    if (loadmore || loadmorey) {

      //console.log("CArgando mas=>",loadmore, loadmorey,posX,posY,this.state.global,this.state.globalnx,this.state.globaly,this.state.globalyny);
        this.setState({ restoreview: false, globaly: parseInt(valy), global: parseInt(val), globalyny: parseInt(valny), globalnx: parseInt(valnx) },
        this.props._CoordsToSets({ clickX: this.state.x, clickY: this.state.x, zoommState: this.props.zoomState.zoommState, scale: e.scale, coords: this.props.coords, positionX: posX, positionY: posY, alreadyAdded: this.props.Items, UsedCoords: this.props.UsedCoords, sets_data: this.props.sets_data, Home: this.props.UserConfiguration.Home })
      )
    }

  }

  renderProgress = () => {
    return <CircularProgress color="secondary" size={50} />;
  }
  render() {
    const {
      limitToBounds,
      panningEnabled,
      transformEnabled,
      pinchEnabled,
      limitToWrapper,
      disabled,
      dbClickEnabled,
      lockAxisX,
      lockAxisY,
      velocityEqualToMove,
      enableWheel,
      enableTouchPadPinch,
      enableVelocity,
      limitsOnWheel,

    } = this.state;
    if (!this.props.ModalStatus && this.state.changeOritentation) {
      this.handleResize();
    }
    const { IsresetingView } = this.props;
   
   
    if (IsresetingView) {
      if (IsresetingView[0]) {
        if(isMobile &&  ( window.innerWidth > window.innerHeight) && this.state.scale!==1.1){
         // this.setState({scale:1.1})
          
        }else{
          if( this.state.scale!==ZOOMPC && !isMobile){
            this.setState({scale:ZOOMPC})
            
          }
        }
      }
    }
    const { steps } = this.state;
    return (
      <ResponsiveContainer scaleme={this.state.scale}>
             { this.state.showride? <Joyride steps={steps} continuous={true} scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}/> :''}
        <div className="App fede" style={{ width: '100%', height: '100%', background: 'gray', }}  >

          <TransformWrapper
            scale={this.state.scale}

            defaultPositionX={this.state.defaultPositionX}
            defaultPositionY={this.state.defaultPositionY}

           // onPanning={this.handPanningStop}


           // onPinching={this.handleWheelEndtransform}

            onPinchingStop={this.handleWheelEnd}
            onPanningStop={this.handleWheelEnd}

            onPanningStart={this.changeState}
            //onWheel={this.handleWheelEndtransform} //hay un metodo para este //commentado 19-10-2020
            onWheelStop={this.handleWheelEnd}

            options={{
              limitToBounds,
              transformEnabled,
              disabled,
              limitToWrapper,

            }}
            pan={{
              disabled: (this.props.letload) ? !this.props.letload : !panningEnabled,
              lockAxisX,//:this.props.zoomState.lockAxisX,
              lockAxisY,//:this.props.zoomState.lockAxisY,
              velocityEqualToMove,
              velocity: enableVelocity,

              velocitySensitivity: 1

            }}
            pinch={{ disabled: (this.props.letload) ? !this.props.letload : !pinchEnabled }}
            doubleClick={{ disabled: !dbClickEnabled }}
            wheel={{
              wheelEnabled: (this.props.letload) ? this.props.letload : enableWheel,
              touchPadEnabled: enableTouchPadPinch,
              limitsOnWheel,
              step: 250
            }}

          >
            {({ zoomIn, zoomOut, onWheelEnd, resetTransform, positionX, positionY,scale,setScale, centerContent, setPositionX, setPositionY,setTransform, ...rest }) => (
              <React.Fragment>
                <div className="" style={{ width: '100%', height: '100%' }}>
                  <TransformComponent >

                    {this.props.Items ? <LoaderItemsbyCoords restore={resetTransform} setPositionX={setPositionX}  setTransform={setTransform}
                    scale={scale} setScale={setScale} setPositionY={setPositionY}   positionX={positionX}  positionY={positionY} 
                    funf={this.handleWheelEndtransform}  /> : <LoadingDimmer estado={this.props.Items ? false : true}></LoadingDimmer>}

                  </TransformComponent>
                </div>
              </React.Fragment>
            )}
          </TransformWrapper>


          {(this.props.UserConfiguration.OpenModalHome) ? <ModalDefaultHome openModalHomeDefault={this.props.UserConfiguration.OpenModalHome} scaleme={this.state.scale} /> : ''}
          <LoadingDimmer estado={this.props.IsLoading}></LoadingDimmer>



        </div>
      </ResponsiveContainer>
    );
  }
}

const mapSetsToProps = state => ({
  zoomState: state.zoomState
  , ModalStatus: state.setModalStatus.ModalStatus
  , UserConfiguration: state.setUserConfiguration
  , IsLoading: state.loaderTransition.IsLoading
  , setNumPage: state.setNumPage
  , sets_data: state.setsSiteController.sets_data
  , coords: state.preLoadCorrds.coords
  , Items: state.itemsDisplayed.Items
  , UsedCoords: state.itemsDisplayed.UsedCoords
  , levelZoom: state.levelZoom.levelZoom
  , letload: state.setLoadingMore.letload
  ,IsresetingView: state.resetView.IsresetingView
}) //state.setModalStatus.ModalStatus

const mapDispatchToPropsActions = dispatch => ({
  setZoomStatus: value => dispatch(setZoomStatus(value)),
  setSetStyles: value => dispatch(setSetStyles(value)),

  getUserConfiguration: value => dispatch(getUserConfiguration(value)),
  getpreLoadCorrds: value => dispatch(getpreLoadCorrds(value)),
  _CoordsToSets: value => dispatch(_CoordsToSets(value)),
  _CoordsToSetsResetImg: value => dispatch(_CoordsToSetsResetImg(value)),
  getpreNewLoadCorrds: value => dispatch(getpreNewLoadCorrds(value)),
  _GenerateMoreCoords: value => dispatch(_GenerateMoreCoords(value)),

});
const AppConnected = connect(mapSetsToProps, mapDispatchToPropsActions)(App);
export default AppConnected;