import { LOAD_MODELS } from '../actions';


export const  models=(state=null, action)=> {
    switch (action.type) {
        case LOAD_MODELS:
          
            return {...state,
                       source:action.payload.source,
                       
                }
         
           default:
              return {...state}
            }
  }