import React, { Component }  from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuRounded from '@material-ui/icons/MenuRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Fab from '@material-ui/core/Fab';
import SearchCustom from './../searchcomponent';
import { ClassCAT1, ClassCAT2, ClassCAT3, ClassCAT4,ClassCAT5,ClassCAT6,ClassCAT7,ClassCAT8,ClassCAT9,ClassCAT10,ClassCAT11 } from '../../constants/constants';
import { CAT1, CAT2, CAT3, CAT4,STUDIOSTITLE,MENSTITLE,MENS,STUDIOS } from '../../constants/constants'
import { Image,Icon,Divider} from 'semantic-ui-react'
import logo from './../holder/logo.png'
import logo_patent from './../holder/patent_logo.png'
import { isMobile, } from 'react-device-detect';

/*export default function SimpleMenu(classMenu,ChangeClassMenu,ChangeClassMenu2,handlelinkshomes,handleFavoritemodal,handleContactModal) {*/
  class SimpleMenu extends Component {
    
    constructor(props){
      super(props)
      this.state={anchorEl:false, setAnchorEl:null}
      this._reCaptchaRef = React.createRef();
    }
 

   handleClick = () => {
    //setAnchorEl(event.currentTarget);
    this.setState({ anchorEl: true })

  };

   handleClose = () => {
   // setAnchorEl(null);
    this.setState({ anchorEl: false})
  };
 
render(){
  const ITEM_HEIGHT = (isMobile)? 60 * 8:'100%';
  return (
    <div>                                                                       
      <Fab color="primary" aria-label="menu" aria-controls="simple-menu"   aria-haspopup="true"   >
        {this.state.anchorEl ? <CloseRoundedIcon fontSize="large" onClick={()=>{this.handleClose()}} /> : <MenuRounded fontSize="large" onClick={()=>{this.handleClick()}} />}
      </Fab>

      <Menu 
        ref={this._reCaptchaRef}
       
        id="simple-menu"
        onClose={this.handleClose}
      
      
        open={this.state.anchorEl}
        
        className= 'dropmenu '
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT,
            
          },
        }}
      >
        <MenuItem><Image centered size='small' alt="World Demo" src={logo} /></MenuItem>
        <MenuItem><Image centered size='small' alt="World Demo" src={logo_patent} /></MenuItem>
        <Divider inverted />
        <MenuItem onClick={()=>{this.handleClose();this.props.handlelinkshomes(CAT1)}} className="dropmenu_item">
          <Icon name='square'className={ClassCAT1} /><span style={{fontSize:'0px'}}>1</span>Architecture</MenuItem>
         <Divider inverted />
        <MenuItem onClick={()=>{this.handleClose();this.props.handlelinkshomes(CAT2)}} className="dropmenu_item">
        <Icon name='square' className={ClassCAT2} /> <span style={{fontSize:'0px'}}>1</span>Nature</MenuItem>
        <Divider inverted />
        
        <MenuItem onClick={()=>{this.handleClose();this.props.handlelinkshomes(CAT3)}} className="dropmenu_item">
        <Icon name='square' className={ClassCAT3} /> <span style={{fontSize:'0px'}}>1</span>Pretty Things</MenuItem>
        <Divider inverted />
        <MenuItem onClick={()=>{this.handleClose();this.props.handlelinkshomes(CAT4)}} className="dropmenu_item">
        <Icon name='square' className={ClassCAT4} /> <span style={{fontSize:'0px'}}>1</span>Scuba</MenuItem>
        <Divider inverted />
        <MenuItem onClick={()=>{this.handleClose();this.props.handleFavoritemodal()}} className="dropmenu_item">
        <Icon name='heart' className='fav_item' /> <span style={{fontSize:'0px'}}>1</span>Favorite</MenuItem>
        {/*<Divider inverted />
        <MenuItem onClick={()=>{this.handleClose();this.props.handleContactModal()}} className="dropmenu_item">
        <Icon name='square' className='sup_item' /> <span style={{fontSize:'0px'}}>1</span>Support</MenuItem>
      <Divider inverted />
        
        <MenuItem className='search_item'><span style={{fontSize:'0px'}}>1</span><SearchCustom /></MenuItem>*/}
      </Menu>
    </div>
  )
}
  }
  export default SimpleMenu;