import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { getModelScenes, } from './../../actions'
import { SITEURLCDN } from './../../constants/constants';
import { GenerateCDN } from './../hook/cdnHook';

import VideoPage from './../../pages/VideoPage'

import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress'
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
    Image, Card, Button, Header,Modal,Icon
} from 'semantic-ui-react'


class FavoriteContainer extends Component {
    contextRef = createRef();
    constructor(props) {
        super(props);
        this.state = {
            openthisInternal: false,
            viewdisp: 'none',
            imageLoded: false,
            modelId: 0,
            Item: this.handleItemData(),
            vid_gal:0,
            modelsSelected:0,
            openVideoModal:false,
            size_modal:0,
            readMore:true,
            txtrMore:'Read More',
            icon:'plus'
        }
       
    }
    toggleText=(key)=> {
        console.log(key)
    
        document.querySelector(".mi_"+key).classList.toggle("truncate");
        let estadoac=this.state.readMore;
        this.setState({icon:(this.state.readMore)?'minus':'plus',txtrMore:(!this.state.readMore)?'Read More':'Read Less',readMore:(estadoac)?false:true})
    }
    renderProgress = () => {
        return <CircularProgress color="secondary" size={50} />;
    }
    loadNewRelated = () => {
        // this.props.handleClose();
        let { set } = this.props;
        this.setState({ openthisInternal: true }, this.props.getModelScenes({ VideoID: set.Id }))

        // this.props.setloaderTransition({IsLoading:true});


    }
    setModalClose = () => {
        this.setState({ openthisInternal: false })

        this.props.setModalStatus({ ModalStatus: false });

    }
    handleShow = () => this.setState({ active: true })
    handleHide = () => this.setState({ active: false })
    HandleVideoPlayButton = () => {
        
        this.setState({ openVideoModal: true, vid_gal:1 })
        
    }
    HandlePhotosButton = () => {
        //
        this.setState({ openVideoModal: true, vid_gal:2})
        
    }
    setVideoModalOpen = (v) => {
      
        this.setState({ openVideoModal: v })
    }
   
    handleItemData = () => {
       // let content = [];
      //  let setItems = [];

        let { set } = this.props;

        let photArray = [];
        let videoArray = [];
        set.content.PLAY.map((item) => {

            if (item['MediaType'] === 'video' && item['Quality'] === '2000kb') {

                videoArray.push(item)
            }

            return item;
        })
        set.content.GALLERY.map((item) => {

            if (item['MediaType'] === 'PHOTO') {

                photArray.push(item)
            }
            return item;
        }
        )
       return [photArray, videoArray, set.content.MODELS] ;
        //this.setState({ Item:Item });
        //console.log(this.state.Item)
        //if(isPhoto){

      //  dispatch(videoloaded({ Item: [photArray, videoArray, content.MODELS] }));
        //dispatch(setItmesContainer(setItems));
    }
    render() {
        let { set, cdntime, zoommState } = this.props;
        //cdntime
        let file = GenerateCDN(set, "/3.jpg", cdntime);
        let img = SITEURLCDN + "" + set.Directory + "/3.jpg" + file;
        img = img.split(' ').join('%20');
        //let img = SITEURL+""+set.Directory+"/3.jpg";
       
       
        
     let btns=1
     if( this.state.Item[0].length>0){
        btns=btns+1
     }
     if( this.state.Item[1].length>0){
        btns=btns+1
     }
     
     
        return (
            <>
            
                <Card raised color='grey'  className={this.props.clname === 'new' ? 'noradius nomargin no-hover fav' : 'noradius no-hover fav'} >
                   
                    <Image src={img} className="noradius" loading="lazy"/>         

                    <Card.Content className="nopadding noradius">
                        <Card.Header as='h5' className="nomargin">
                        <div style={{fontSize: "1.1rem"}} className={"mi_"+set.Id+" truncate"}> {set.title}</div>
                         <span onClick={()=>this.toggleText(set.Id)} style={{ paddingLeft: '0px',fontSize: "0.9rem", cursor:'pointer'}}>{this.state.txtrMore}<Icon name={this.state.icon +' square outline'}  className="readMore"size='small' style={{ paddingLeft: '10px', cursor:'pointer'}} /></span>
                        </Card.Header>

                    </Card.Content>
                    <Card.Content extra className="nopadding">

                        <Button.Group widths={btns} >
                            { this.state.Item[1].length>0 ? 
                                <Button basic inverted color='green' className="nopadding noshadow"  onClick={this.HandleVideoPlayButton}><PlayCircleOutlineOutlinedIcon style={{ fontSize: (zoommState === 'zoom1' || zoommState === 'zoom4'  || zoommState==='zoom2') ? 15 : 30, verticalAlign: "middle", cursor: "pointer" }} /></Button>
                            :''}
                            { this.state.Item[0].length>0 ? 
                                <Button inverted basic color='blue' className="nopadding noshadow" onClick={this.HandlePhotosButton}><CameraAltIcon style={{ fontSize: (zoommState === 'zoom1' || zoommState === 'zoom4'  || zoommState==='zoom2') ? 15 : 30, verticalAlign: "middle", cursor: "pointer" }} /></Button>
                            :''}    
                            <Button inverted basic color='red' className="nopadding noshadow" onClick={()=>this.props.unfavourite(set.isfav[0].Id)}><FavoriteIcon style={{ fontSize: (zoommState === 'zoom1' || zoommState === 'zoom4' || zoommState==='zoom2') ? 15 : 30, verticalAlign: "middle", cursor: "pointer" }} /></Button>
                        </Button.Group>


                    </Card.Content>
                </Card>
                <Modal key={`modalsetfav-${set.Id}`}
                    onClose={() => this.setVideoModalOpen(false)}
                    open={this.state.openVideoModal}
                    size={this.state.size_modal === 0 ? "fullscreen" : "large"}
                >

                    <Modal.Content>
                        <VideoPage cdntime={cdntime} videoItem={this.state.Item} vid_gal={this.state.vid_gal} modelsSelected={this.state.modelsSelected} handleClose={this.handlelevelcero}></VideoPage>
                        <div className="Info_vid_page">
                            <div className="Info_vid_SubContent_page" >
                                <Header as='h2' inverted textAlign='left'>
                                    <Link to={'#'} onClick={() => this.setVideoModalOpen(false)}>
                                        <Icon link circular name='arrow left'  className="blink"/>
                                    </Link>
                                </Header>


                            </div>
                        </div>
                    </Modal.Content>

                </Modal>
            </>

        )
    }
}
const mapDispatchToPropsActions = dispatch => ({

    getModelScenes: value => dispatch(getModelScenes(value)),
    


});
export default connect(null, mapDispatchToPropsActions)(FavoriteContainer);
   // export default  FavoriteContainer