import React from 'react'
import ItemsContainer from './../itemsContainer'
import { isMobile,isTablet } from 'react-device-detect';
import {

  Header,
  Image, Card,
  Grid,
  Segment,
} from 'semantic-ui-react'


export const ModelPageInfo = ({ model, handlelevelOne, cdntime, setModalOpenRelated = false }) => {





  return (
    <Segment style={{ padding: '8em 1em' }} inverted>
      <Header inverted as={(!isMobile) ? 'h1' : 'h3'} >{model.name} </Header>
      <Grid columns={2} divided className="nomargin ">
        <Grid.Row className='nomargin'>

          <Grid.Column >
            <Segment><Image src={model.thumb} /></Segment>
          </Grid.Column>
          <Grid.Column>
           
          <Card.Group style={{ margin: '0' }} itemsPerRow={(isMobile && !isTablet) ? '1' : '2'} >

           

              {model.sets ? model.sets.map((item, i) => {
                let ritem = '';
                if (isMobile) {
                  if (i < 2) {
                    ritem = <ItemsContainer key={`scene_model_related${i}`} set={item} cdntime={cdntime} handleClose={handlelevelOne} setModalOpenRelated={setModalOpenRelated} />
                  }
                } else {
                  if (i < 6) {
                    ritem = <ItemsContainer key={`scene_model_related${i}`} set={item} cdntime={cdntime} handleClose={handlelevelOne} setModalOpenRelated={setModalOpenRelated} />
                  }
                }
                return ritem;
              }) : this.renderProgress()}
            </Card.Group>

          </Grid.Column>

        </Grid.Row>
      </Grid>
      {(isMobile) ? model.sets.length > 2 ?
        <Segment inverted style={{ borderRadius: 0 }} >
          <Grid columns={1} divided  className="nomargin" >
            <Header inverted className="nomargin" >More like this:</Header>
            <Grid.Column>
            <Card.Group style={{ margin: '0' }} itemsPerRow={(isMobile) ? '2' : '4'} >
              {model.sets ? model.sets.map((item, i) => {
                let ritem = '';
                if (i >= 2) {
                  ritem = <ItemsContainer key={`scene_model_related${i}`} set={item} cdntime={cdntime} handleClose={handlelevelOne} setModalOpenRelated={setModalOpenRelated} />
                }
                return ritem;
              }) : this.renderProgress()}
            </Card.Group>
            </Grid.Column>
          </Grid>
        </Segment> : ''
        : model.sets.length > 6 ?
          <Segment inverted style={{ borderRadius: 0 }} >
            <Grid >
              <Header inverted className="nomargin" style={{width:'100%'}}>More like this:</Header>
              <Card.Group style={{ margin: '0' }} itemsPerRow={(isMobile) ? '2' : ( model.sets &&  model.sets.length >6 && model.sets.length < 8)?'1':'4'} >
                {model.sets ? model.sets.map((item, i) => {
                  let ritem = '';
                  if (i > 6) {
                    ritem = <ItemsContainer key={`scene_model_related${i}`} set={item} cdntime={cdntime} handleClose={handlelevelOne} setModalOpenRelated={setModalOpenRelated} />
                  }
                  return ritem;
                }) : this.renderProgress()}
              </Card.Group>

            </Grid>
          </Segment> : ''
      }
    </Segment>
  )

}
//{/*<Link to={`/ModelPage/${model.id}`} key={`lnk/ModelPage/${model.id}`} className="link button" style={{color:'#757575',cursor:'pointer',fontWeight:'700'}} >*/}