import React from 'react'
//import { Link } from 'react-router-dom';


export const getPopcontent = (modelsArr,fn) => {
  

  if (modelsArr.length > 0) {
    return modelsArr.map((model, i) => {
      let a=i+1;
      return (
        
        <span style={{ paddingLeft: '10px', cursor:'pointer'}} key={`lnk/ModelPage/${model.id}`} onClick={()=>{ fn(model.id)}}>
          {model.name}
          {(  modelsArr.length!==a )? ', ' :''}
        </span>
      );
    })
  }
}
//{/*<Link to={`/ModelPage/${model.id}`} key={`lnk/ModelPage/${model.id}`} className="link button" style={{color:'#757575',cursor:'pointer',fontWeight:'700'}} >*/}