import { createStore, applyMiddleware } from 'redux' //, compose
import throttle from 'lodash.throttle';
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';
import reducers from './../reducers/';
import {zoomState} from './../services/states'
//16112020
import { loadState, saveState } from './persistor'
const persistConfig = loadState();
const Config = {
   key: 'ZoomKeyStore',
    storage: localforage,
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
   };

const initialState = {zoomState};





//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // dev
const pReducer = persistReducer(Config, reducers);
//export const store = createStore(pReducer, initialState ,composeEnhancers(applyMiddleware(thunk)))//  dev2
export const store = createStore(pReducer, initialState ,applyMiddleware(thunk))  //live
  store.subscribe(throttle(() => {
    saveState({
        todos: store.getState().todos
    });
}, 1000));
export const persistor = persistStore(store);  

//export const store = createStore(reducers, initialState ,composeEnhancers(applyMiddleware(thunk))) // Dev
//Generamos  el store de redux

//export const store = createStore(reducers, initialState ,composeEnhancers(applyMiddleware(thunk))) // Dev
//export const store = createStore(reducers, initialState ,applyMiddleware(thunk)) // Live
//export const store = createStore(zoomState, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()); debuggin sin middleware   