import React from 'react'
import PropTypes from 'prop-types'
//import DesktopContainer from './DesktopContainer';

import MobileContainer from './MobileContainer';
export const ResponsiveContainer = ({ children,scaleme }) => (
    <div>
        
        <MobileContainer scaleme={scaleme}>{children}</MobileContainer>
        
    </div>
)

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}