import React from 'react';

import { useInView } from 'react-intersection-observer';
import { Image,  } from 'semantic-ui-react'
import loading from './../holder/loading.jpg'
export const InScreenView = ({ children }) => {
   
   
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '0px 0px 0px 0px',
        //trackVisibility: true,
        //delay: 100
    });
    
      
    return (<div ref={ref} >

        {inView ?
           
                children
           
            :
          
            <Image
                src={loading}
                className="setImage"
                alt="World of men"
                style={{ width: '100%', height: '100%' }}
                 >
            </Image>
          
           }

    </div>)
}