import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player'
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Grid, Button, Icon, Popup, Modal, Header, Image } from 'semantic-ui-react'
import VideoPage from './../../pages/VideoPage'
import {SITEURLCDN,SITEMEMBERURLCDN} from './../../constants/constants';
import {GenerateCDN} from './../hook/cdnHook';
import "./Video.css";
import { getPopcontent } from './../helpers/ModalInfo'
class VideoSeeApp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openVideoModal: false,
            vid_gal:0,
            size_modal:0,
            modelsSelected:0,
            added: this.props.myItem.isfav.length > 0? true : false,

        }
    }
    player = {}
    onPlayerReady(player) {
        console.log("Player is ready: ", player);
        // player.play();
        this.player = player;
    }

    onVideoPlay(duration) {
        console.log("Video played at: ", duration);
    }

    onVideoPause(duration) {
        console.log("Video paused at: ", duration);
    }

    onVideoTimeUpdate(duration) {
        console.log("Time updated: ", duration);
    }

    onVideoSeeking(duration) {
        console.log("Video seeking: ", duration);
    }

    onVideoSeeked(from, to) {
        console.log(`Video seeked from ${from} to ${to}`);
    }

    onVideoEnd() {
        console.log("Video ended");
    }
    HandleVideoPlayButton = () => {
        this.setState({ openVideoModal: true, vid_gal:1 })
    }
    HandlePhotosButton = () => {
        this.setState({ openVideoModal: true, vid_gal:2})
        
    }
    HandleModelButton = (v) => {
        this.setState({ openVideoModal: true, vid_gal:3, size_modal:1,modelsSelected:v })
        
    }
    setVideoModalOpen = (v) => {
        this.setState({ openVideoModal: v })
    }
    handlelevelcero = (v) => {
        this.props.handleClose();
    }
    handleFavorite = () => {
        this.props._favorite(this.props.Id);
        this.setState({added:true})

    }
    handleUnFavorite = () => {
        
        this.props._unfavourite(this.props.related_scenes[0].isfav,this.props.related_scenes[0].isfav[0].Id);
        this.setState({added:false})
    }

       render() {
        let {myItem,cdntime,category} =this.props;
        //cdntime
        let filevideo = GenerateCDN(myItem, this.props.previewvid , cdntime,'members');
        let videoFiles=SITEMEMBERURLCDN + "" + myItem.Directory + this.props.previewvid + filevideo;
        videoFiles= videoFiles.split(' ').join('%20');

        let file = GenerateCDN(myItem, this.props.previewvidposter , cdntime);
        let videopreview = SITEURLCDN + "" + myItem.Directory + this.props.previewvidposter  + file;
        let fullvideo='fullvideo_'+this.state.vid_gal
        
        videopreview= videopreview.split(' ').join('%20');
        return (

            <div style={{ position: 'relative' }} className={this.props.previewvid ? 'player-wrapper' : ""}>





                {this.props.previewvid ? <ReactPlayer controls={false}
                    muted={true}
                    playing
                    className='react-player first_VID'
                    url={videoFiles}
                    width='100%'
                    height='100%'
                /> : <Image
                        src={videopreview}
                        className="setImage"
                        alt={`${this.props.title}`}
                        style={{ maxWidth: '100%', }}

                    />}

                <div className=" Info_vid">
                    <div className="Info_vid_SubContent" >
                        {(isMobile) ? 
                            <h3>  {this.props.title}</h3> 
                        : 
                            <h1>  {this.props.title}</h1>
                        }
                    
                        
                        <Grid columns={ 1} divided inverted={true} padded>
                                    
                                    <Grid.Column verticalAlign='middle' className="nopadding" >
                                        <Header inverted as={(!isMobile) ? 'h3': 'h5'}  className="nomargin" >Featuring: 
                                        {this.props.models ?
                                            getPopcontent(this.props.models, this.HandleModelButton)
                                            : ''}
                                        
                                        </Header>
                                    </Grid.Column>
                                </Grid>
                        <Grid columns={2} relaxed='very' stackable>
                            <Grid.Column className={(isMobile) ? "nopadding": ""}  >
                                <Button.Group  size={( isMobile) ? 'tiny' : 'medium'} widths={(this.props.videoItem[1].length > 0 && this.props.videoItem[0].length) ? '3' : '2'} className="noradius"   >
                                    { this.props.videoItem[1].length > 0 ?
                                        <>
                                        
                                                <Button  className= "noradius" icon='play' inverted content='PLAY' color='grey'  onClick={this.HandleVideoPlayButton} />
                                                {this.props.videoItem[0].length > 0 ? 
                                                <Button  className={(!isMobile) ? "noradius": "nopaddingx noradius"} icon='camera' inverted content='PHOTOS' color='grey' onClick={this.HandlePhotosButton} />
                                                 :''   }
                                            

                                        
                                        </>
                                        : 
                                                <Button  className={(!isMobile) ? "noradius": "nopaddingx noradius"} icon='camera' content='PHOTOS' inverted color='grey' onClick={this.HandlePhotosButton} />
                                        
                                        }
                                
                                    <Popup
                                        trigger={
                                            <Button icon='heart'  className={(!isMobile) ? "noradius": "nopaddingx noradius"} content={this.state.added ? '- LIST':'+ LIST'} inverted={this.state.added ? false : true} color='green' onClick={this.state.added ? this.handleUnFavorite : this.handleFavorite} />
                                        }
                                        content={this.state.added ? '- LIST':'+ LIST'}


                                    />
                                </Button.Group>
                            </Grid.Column>
                        </Grid>
                        <Modal 
                            onClose={() => this.setVideoModalOpen(false)}
                            open={this.state.openVideoModal}
                            size={this.state.size_modal===0 ? "fullscreen" : "large"}
                            className={fullvideo}
                        >

                            <Modal.Content>
                                <VideoPage category={category} myItem={myItem} cdntime={cdntime} videoItem={this.props.videoItem} vid_gal={this.state.vid_gal} modelsSelected={this.state.modelsSelected} handleClose={this.handlelevelcero}></VideoPage>
                                <div className="Info_vid_page">
                                    <div className="Info_vid_SubContent_page" >
                                        <Header as='h2' inverted textAlign='left'>
                                            <Link to={'#'} onClick={() => this.setVideoModalOpen(false)}>
                                                <Icon circular link name='arrow left'  className="blink"/>
                                            </Link>
                                        </Header>


                                    </div>
                                </div>
                            </Modal.Content>

                        </Modal>

                    </div>
                </div>

            </div >

        );
    }
}
const mapSetsToProps = state => ({ videoItem: state.videoloaded.Item, related_scenes: state.setModelScenes.related_scenes })
export default connect(mapSetsToProps, null)(VideoSeeApp);

