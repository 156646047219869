import { isMobile,isTablet } from 'react-device-detect';
export const INCREASE = 'INCREASE'
export const DECREASE = 'DECREASE'

export const PRCTOWHIDTH=1.77
export const INITPRCTOWHIDTH=1.77//(isMobile)?1.77:1.5;
export const NUMOFFZOOMALLOWED=(isMobile)? 3 : 4;


export const TWINKS = 13; //13
export const DADDYANDBOY = 14; //14 

export const CAT1 = 13; //13
export const CAT2 = 14; //14
export const CAT3 = 15; //15
export const CAT4 = 16; //16
export const MENS = [13,14,15,16]; //16
export const MENSTITLE = ["Twinks", "Daddy / Boy", "Hunks", "Bears"]; //16
export const STUDIOS = [72,76,90,97,98,118,148]; //ORDEN ORIGINAL 
export const STUDIOSTITLE = ["Southern Strokes","Bring me a Boy","Hairy and Raw","Twinks in Shorts","Bareback That Hole","Breed Me Raw","Bear Films"]; //ORDEN ORIGINAL 

export const ClassCAT1 = "set13"; //13  
export const ClassCAT2 = "set14"; //14
export const ClassCAT3 = "set15"; //15
export const ClassCAT4 = "set16"; //16
export const ClassCAT5 = "set72"; 
export const ClassCAT6 = "set76"; 
export const ClassCAT7 = "set90"; 
export const ClassCAT8 = "set97"; 
export const ClassCAT9 = "set98"; 
export const ClassCAT10 = "set118"; 
export const ClassCAT11 = "set148"; 

export const ColorCAT1 = "yellow"; //13
export const ColorCAT2 = "purple"; //14
export const ColorCAT3 = "black"; //15
export const ColorCAT4 = "red"; //16

 
export const DEFPCZOOM='zoom1'; //zoom2
export const DEFMOBZOOM=(isTablet)?'zoom2':'zoom4';//zoom6
export const SITEURL='//newworlddemo.com/tour/content/';
export const SITEURLCDN='https://x8r8e2j4.ssl.hwcdn.net/tour/content/';
export const SITEMEMBERURLCDN='https://x8r8e2j4.ssl.hwcdn.net/members/content/upload/';
export const CDNKEY='oHRcNgdpyAR2hmu5edpI7Ygqdca8hRvF';//zoom6
export const ADMINFILES='https://newworlddemo.com/Demo/api-rest/';//zoom6  https://newworlddemo.com/demo/
export const MAILURL=ADMINFILES+'recaptcha/captcha_verify.php?';//zoom6  https://newworlddemo.com/demo/
export const PASSWORDRETRIVE='https://start.worldofmen.com/signup/password.php?';//zoom6  https://newworlddemo.com/demo/
export const SID=10
export const SITEKEY='6Ld4t98ZAAAAAPgW6G9UMgUAui0i-wGjid3s2TuC';
export const SECRET='oHRcNgdpyAR2hmu5edpI7Ygqdca8hRvF';
export const VERSION='V 1.00';

export const ZOOMPC=2.8
export const ZOOMMOBILE=(isTablet)?1.8:1.1
const d = new Date();
export const timercdn=d.getTime();


