import { INITPRCTOWHIDTH, CAT1, CAT2, CAT3, CAT4, MENS, STUDIOS } from './../constants/constants'
import { getPorce, } from './functions'
export const coordGenerator_actual = (home, zoommState, sets_data, HomeType) => {


    let uno = 0;
    let dos = 0;
    let tres = 0;
    let cuatro = 0;

    let tres_cat = 0;
    let dos_cat = 0;
    let uno_cat = 0;
    let cuatro_cat = 0;
    home = parseInt(home);
    if (home === CAT1) {
        uno = sets_data[0].length; //71
        dos = sets_data[1].length; //75
        tres = sets_data[2].length; //140
        cuatro = sets_data[3].length; //160
        
        uno_cat = CAT1; //71
        dos_cat = CAT2; //75
        tres_cat = CAT3; //140
        cuatro_cat = CAT4; //160
    }
    if (home === CAT2) {
        uno = sets_data[1].length;
        dos = sets_data[0].length;
        tres = sets_data[2].length;
        cuatro = sets_data[3].length;

        uno_cat = CAT2; //71
        dos_cat = CAT1; //75
        tres_cat = CAT3; //140
        cuatro_cat = CAT4; //160
    }
    if (home === CAT3) {
        uno = sets_data[2].length;
        dos = sets_data[1].length;
        tres = sets_data[0].length;
        cuatro = sets_data[3].length;

        uno_cat = CAT3; //71
        dos_cat = CAT2; //75
        tres_cat = CAT1; //140
        cuatro_cat = CAT4; //160
    }

    if (home === CAT4) {
        uno = sets_data[3].length;
        dos = sets_data[2].length;
        tres = sets_data[1].length;
        cuatro = sets_data[0].length;

        uno_cat = CAT4; //71
        dos_cat = CAT3; //75
        tres_cat = CAT2; //140
        cuatro_cat = CAT1; //160

    }
    let items = [];
    let items_otros = [];
    let limits = [0, 0, 0, 0];

    let matriz = fn_matriz(uno, 0); //ceil(sqrt($uno));
    items = coordsBuild(zoommState, matriz, uno_cat, items, items_otros);
    
    

    matriz = fn_matriz(Math.pow(matriz, 2), dos);
    items = coordsBuild(zoommState, matriz, dos_cat, items[0], items[1]);

    if (tres > 0) {

        matriz = fn_matriz(Math.pow(matriz, 2), tres);
        items = coordsBuild(zoommState, matriz, tres_cat, items[0], items[1]);
    }
    if (cuatro > 0) {
        matriz = fn_matriz(Math.pow(matriz, 2), cuatro);//ceil(sqrt($uno + $dos + $tres + $cuatro));
        items = coordsBuild(zoommState, matriz, cuatro_cat, items[0], items[1]);
    }
    limits = [maxValueInArray(items[0], 0, "1", zoommState)+5
        , maxValueInArray(items[0], 1, "1", zoommState)+5
        , maxValueInArray(items[0], 0, "-1",zoommState)-5
        , maxValueInArray(items[0], 1, "-1", zoommState)-5];


    let allMap = items[0];
   

    // -----------------Y > 0 -----------
   
    let CB=[];//(x,-y) X>=0
    let CC=[];//(-x,y) y>=0
    let CD=[];//(-x,-y) y<=0
    let CA=[];//(x,y) X>=0
    
    allMap.map((itmes) => {
      
        if(parseInt(itmes[0]) >= 0 && parseInt(itmes[1])>=0){
           
            CA.push(itmes);
        }
        if(parseInt(itmes[0]) >=0 && parseInt(itmes[1])<=0){
            CB.push(itmes);
        }
        if(parseInt(itmes[0]) <= 0 && parseInt(itmes[1])>=0){
            CC.push(itmes);
        }
        if(parseInt(itmes[0]) <=0 && parseInt(itmes[1])<=0){
            CD.push(itmes);
        }
    return true;
      
    })
   
    
    return  { limites: limits, coordenadas: allMap,splets:[CA,CB,CC,CD]};
}
function fn_matriz(materior, sets) {
    let mt = 0;
    for (let i = 2; i < 1000; i += 2) {
        if (materior <= i * i) {

            if (((i * i) - (materior + sets)) >= sets) {

                mt = i;
                break;
            }

        }
    }
    return mt;
}

export const evaluateCoords = (coords, X, Y) => {
    let newcoors = [];//coords;
  
    //foreach($coords as $xyc)
    coords.map((xyc) => {
     

        newcoors.push( [xyc[0]+ (X * 2), xyc[1] + (Y * 2), xyc[2]]);
        return 0;
    })

    return newcoors;
}
export const maxValueInArray= (array, keyToSearch, op, zoommState) => {
    let w = Math.round(window.innerWidth * getPorce(zoommState));
    let h = Math.round(w / INITPRCTOWHIDTH);
    let currentMax = 0;
    //foreach($array as $arr)
    array.map((arr) => {

        //foreach($arr as $key => $value)
        arr.map((value, key) => {
            if (op > 0 && (key === keyToSearch && (value >= currentMax))) {
                let sum = (keyToSearch === 0) ? w : h;
                currentMax = value + sum;

            }
            if (op < 0 && (key === keyToSearch && (value <= currentMax))) {

                currentMax = value;
            }
            return currentMax;
        })
        return currentMax
    })

    return currentMax;
}
function coordsBuild(zoommState, matriz, cat, items, items_otros) {

    let MYscreenwidth = Math.round(window.innerWidth * getPorce(zoommState));
    let myHeight = Math.round(MYscreenwidth / INITPRCTOWHIDTH);

    let ctoporY = matriz;
    let ctoporX = matriz;

    let coordxtodrow = 0;
    let xDir = 1;
    let colly = 0;
    let ystarton = 0;


    let band0 = false;
    let fill_x, agregar;
    

    for (let j = 0; j < ctoporY; j++) {


        if (j >= ctoporY / 2 && !band0) {
            ystarton = (ctoporY / 2 * myHeight) * -1;
            band0 = true;
            colly = 0;
        }
        if (j > 0) {
            if (ystarton < 0) {
                colly = (colly - (myHeight));
                colly=colly-5;
            } else {
                colly = colly + myHeight;
                colly=colly+5;
            }
        }
        fill_x = 0;
        coordxtodrow = 0;
        
        for (let i = 0; i < Math.round(ctoporX / 2); i++) {

            if (i > 0) {

                fill_x = fill_x + MYscreenwidth;

            } else {
                if (coordxtodrow > 0 && xDir > 0) {
                    fill_x = coordxtodrow * -1;
                } else {
                    fill_x = coordxtodrow;

                }
            }


            agregar = true
            for (let x = 0; x < items_otros.length; x++) {
                if (items_otros[x] === Math.ceil(fill_x) + "x" + Math.ceil(colly)) {
                    agregar = false;
                }
            }
            if (agregar) {
                fill_x=(fill_x!==0)? fill_x + 5:0;
                
                items_otros.push(Math.ceil(fill_x) + "x" + Math.ceil(colly));
                items.push([Math.ceil(fill_x), Math.ceil(colly), cat]);
                //$numofsets--;
            }

        }

        coordxtodrow = MYscreenwidth * -1;

        xDir = -1;

        for (let i = 0; i < Math.floor(ctoporX / 2); i++) {
            if (i > 0) {
                fill_x = fill_x - MYscreenwidth;

            } else {
                if (coordxtodrow > 0 && xDir > 0) {
                    fill_x = coordxtodrow * -1;
                } else {
                    fill_x = coordxtodrow;

                }
            }


            agregar = true
            
            for (let x = 0; x < items_otros.length; x++) {
                if (items_otros[x] === Math.ceil(fill_x) + "x" + Math.ceil(colly)) {
                    agregar = false;
                }
                
            }
            if (agregar) {
                fill_x=fill_x-5;
                
                items_otros.push(Math.ceil(fill_x) + "x" + Math.ceil(colly));
                items.push([Math.ceil(fill_x), Math.ceil(colly), cat]);
                //$numofsets--;
            }
        }
        xDir = 1;
    }
    return [items, items_otros];
}
export const checktogenerate = (positionX,positionY,coords,scale)=>{
        let x0 = positionX ;
        let y0 =  positionY;

        let x1=coords.limites[0];
        let y1=coords.limites[1];
        let x2=coords.limites[2];
        let y2=coords.limites[3];
        let x=positionX*-1;
        let y=positionY*-1;
        if(scale!==1 ){
            let xx = positionX *-1;
            let yy =  positionY *-1;
       
            x = Math.round(xx/scale);
            y =  Math.round(yy/scale);
        }
       
        let generate=false;
       // console.log("x0="+x0," y0="+y0," x1="+x1," y1="+y1," x2=>"+x2," y2="+y2," x=>"+x +">"+ ( ( x2 / 4 ) * 3),"y=>"+ y +">"+ ((y / 4 ) * 3));
      
        if(x0>0){
            if(y0>0){
                if(y < ((y2 / 4 ) * 3) ){
                    generate=true;
                    
                }
            }else{
                if(y > ((y2 / 4 ) * 3)*-1 ){
                    generate=true;
                    
                }
            }
            if( x < ( ( x2 / 4 ) * 3)){
                generate=true; 
                
            }
            
        }else{
            if(y0>0){
                if(y < ((y2 / 4 ) * 3) ){
                    generate=true;
                 
                }
            }else{
                if(y > ((y1 / 4 ) * 3) ){
                    generate=true;
                 
                }
            }
            if( x > ( ( x1 / 4 ) * 3)){
                 generate=true; 
                 
            }
        }
        
    return generate;
}
function getCAtegorieOrder(home) {
    let categories=[];
    if (STUDIOS.includes(parseInt(home))) {
        categories=STUDIOS;
    }else{
        categories=MENS;
    }   
        let idx = categories.indexOf(parseInt(home));
        let narr = [categories[idx]];
       // console.log(narr,idx,home)
        for (let j = 0; j < categories.length; j++) {
            if (idx - j >= 0) {
                narr[idx - j] = categories[j];
            } else {
                narr[j] = categories[j];
            }
        }
  return narr; 
}
/************************************************************************** */
export const coordGenerator = (home, zoommState, sets_data) => {
    let categories=[];
    if (STUDIOS.includes(parseInt(home))) {
        categories=STUDIOS;
    }else{
        categories=MENS;
    }   

    let categoriesOrder=getCAtegorieOrder(home);
    //console.log("categoriesOrder",categoriesOrder,home);
    let items = [];
    let items_otros = [];
    let limits = [0, 0, 0, 0];
    
    /////// borrar /////////////
   
    ///////borrar////////////////
    let matriz
    for(let k=0;k<categoriesOrder.length;k++){
        let idx = categories.indexOf(parseInt(categoriesOrder[k]));

       // numbers[k]=sets_data[idx].length;
        //catPosition[k]=categoriesOrder[k];

        if(k===0){
            matriz = fn_matriz(sets_data[idx].length, 0); //ceil(sqrt($uno));
            items = coordsBuild(zoommState, matriz, categoriesOrder[k], items, items_otros);
        }else{
            matriz = fn_matriz(Math.pow(matriz, 2), sets_data[idx].length);
            items = coordsBuild(zoommState, matriz, categoriesOrder[k], items[0], items[1]);
        }
    }

  




    limits = [maxValueInArray(items[0], 0, "1", zoommState) + 5
        , maxValueInArray(items[0], 1, "1", zoommState) + 5
        , maxValueInArray(items[0], 0, "-1", zoommState) - 5
        , maxValueInArray(items[0], 1, "-1", zoommState) - 5];


    let allMap = items[0];


    // -----------------Y > 0 -----------

    let CB = [];//(x,-y) X>=0
    let CC = [];//(-x,y) y>=0
    let CD = [];//(-x,-y) y<=0
    let CA = [];//(x,y) X>=0

    allMap.map((itmes) => {

        if (parseInt(itmes[0]) >= 0 && parseInt(itmes[1]) >= 0) {

            CA.push(itmes);
        }
        if (parseInt(itmes[0]) >= 0 && parseInt(itmes[1]) <= 0) {
            CB.push(itmes);
        }
        if (parseInt(itmes[0]) <= 0 && parseInt(itmes[1]) >= 0) {
            CC.push(itmes);
        }
        if (parseInt(itmes[0]) <= 0 && parseInt(itmes[1]) <= 0) {
            CD.push(itmes);
        }
        return true;

    })


    return { limites: limits, coordenadas: allMap, splets: [CA, CB, CC, CD] };
}