
import {PRCTOWHIDTH,INITPRCTOWHIDTH} from './../constants/constants';
const columns = {
  zoom1: 0.0833,
  zoom2: 0.16666,
  zoom3: 0.25,
  zoom4: 0.3325,
  zoom5: 0.4166,
  zoom6: 0.5,
  zoom12: 1

}
const ancho = {
  zoom1: 200,
  zoom2: 307,
  zoom3: 480,
  zoom4: 633,
  zoom5: 960,
  zoom6: 960,


}
const alto = {
  zoom1: 200,
  zoom2: 150,
  zoom3: 331,
  zoom4: 310,
  zoom5: 311,
  zoom6: 311,


}
export const getWidth = (colnumber) => {
  return ancho[colnumber];
}
export const getHeigth = (colnumber) => {
  return alto[colnumber];
}
export const getPorce = (colnumber) => {
  //this.setState({ procentaje: columns[colnumber] });
  return columns[colnumber];
}
export const styleme = (x, y) => {

  const divStyle = {
    msTransform:'translate(' + x + 'px,' + y + 'px)',
    WebkitTransform:'translate(' + x + 'px,' + y + 'px)',
    MozTransform:'translate(' + x + 'px,' + y + 'px)',
    OTransfor:'translate(' + x + 'px,' + y + 'px)',
    transform: 'translate(' + x + 'px,' + y + 'px)',
    position: 'absolute',
    height: 'auto',
    cursor:'grab',
  };
  return divStyle;
}

export const getSetItem = (sets_data, idx) => {
  if(sets_data.length > idx){
    return sets_data[idx];
  /*return (sets_data.find(
    function (value, index) {
      
      if (index === idx) {
        return value;
      }
      return '';
    }
  ))*/
}else{
  /*return (sets_data.find(
    function (value, index) {

      let res= (sets_data.length *  Math.ceil(idx/sets_data.length)) - idx ;
    
      
      if (index === res) {
       
         return value;
      }
      return '';
    }
  ))*/
  return '';
}
}
export const objhandleitems = (zoommState,numoffSets,currentW,currentH,restoreview) => {
  return {
      zoommState: zoommState
      ,numoffSets: numoffSets
      ,currentW: currentW
      ,currentH: currentH
      ,restoreview:restoreview }
}

export const trnsalteCoords = (x, y, ctoporX, ctoporY, getPorce, fill_x, colly, coordxtodrow, ultimaxr, ultimaxl, xDir, ystarton, procentaje,zoommState) => {
  let items = [];
  let MYscreenwidth = Math.round(parseInt( window.innerWidth * getPorce));
  let myHeight=MYscreenwidth/((getPorce===0.5)?INITPRCTOWHIDTH:PRCTOWHIDTH);
  
  for (let j = 0; j < ctoporY; j++) {
    if (j > 0) {
      if (ystarton < 0) {
      
            colly = colly + (myHeight * -1);
        

      } else {
          colly = colly +myHeight;
      }
    }
    for (let i = 0; i < (ctoporX); i++) {
      if (i > 0) {
        if (coordxtodrow > 0 && xDir > 0) {
          let zz = Math.round(parseInt(window.innerWidth * procentaje));
          let fill_xx = parseFloat(fill_x - zz);
          fill_x = fill_xx * 1;
        } else {
          fill_x = fill_x + (window.innerWidth * getPorce);
        }
      } else {
        if (coordxtodrow > 0 && xDir > 0) {
          fill_x = coordxtodrow * -1;//((window.innerWidth * getPorce)) * -1;
        } else {
          fill_x = coordxtodrow;
        }
      }
      const divStyle = styleme(fill_x, colly);
      items.push(divStyle);
    }
    fill_x = 0;
    if (ultimaxr === 0 && ultimaxl > x) {
    }
  }
  return items;
}  