import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import './../../App.css'
const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    position:"relative"
  },
  container: {
    display: 'flex',
  },
  paper: {
    margin: theme.spacing(1),
    position:"absolute",
    //backgroundColor: "rgba(0, 0, 0, 0.7)",
    color:"#fff"
  },
  paperTitle: {
    margin: theme.spacing(1),
    position:"absolute",
    backgroundColor: "rgba(0, 0, 0, 1)",
    color:"#fff"  ,  padding: "2px 0"
  },
 

  itemtop:{
    top:0
  },
  itembottom:{
    top:'38%' 
 },
  itemtitlebottom:{
    bottom:'0%' ,
    display: "flex",
    justifyContent: "center",
    width:'100%',
    margin:0,
    textAlign:'center',
 }
}));

export default function CalltoAction(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  
  return (
    <div className={classes.root} >
        {!isMobile ? 
            <div className={classes.container} onMouseEnter={handleChange} onMouseLeave={handleChange}>
                
                {props.children}
              
                <Grow
                in={checked}
                style={{ transformOrigin: '0 0 0' }}
                {...(checked ? { timeout: 1700 } : {})}
              >
                <Paper elevation={4} square={true} className={`${classes.paperTitle}  ${classes.itemtitlebottom}  `}>
                  <h5 style={{ fontSize: ((props.zoomstate==='zoom1' || props.zoomstate==='zoom4'  || props.zoomstate==='zoom2') && (window.innerWidth>1500 || isMobile))? 8 : 5, }}>{props.Item.title}</h5>
                </Paper>
              </Grow>
            </div>
          :
          <div className={classes.container} onMouseEnter={handleChange} onMouseLeave={handleChange}>
                
          {props.children}
        
          
          <Paper elevation={4} square={true} className={`${classes.paperTitle} ${classes.itemtitlebottom}   `}>
            <h5 style={{ fontSize: ((props.zoomstate==='zoom1' || props.zoomstate==='zoom4'  || props.zoomstate==='zoom2') && (window.innerWidth>1500 || isMobile))? "4.5px" : "5px", }}>{props.Item.title}</h5>
          </Paper>
        
      </div>
          }
    </div>
  );
}
