import { CLEAN_QUERY,START_SEARCH,FINISH_SEARCH,UPDATE_SELECTION } from '../actions';

const initialState = {
    loading: false,
    results: [],
    value: '',
  }
export const  searchManager=(state=null, action)=> {
    switch (action.type) {
      case CLEAN_QUERY:
        return { ...state,initialState}
      case START_SEARCH:
          
        return { ...state, loading: true, value: action.payload.query }
      case FINISH_SEARCH:
          let res=[];
        if(action.payload.results.length>0){
            action.payload.results.map((item,i)=>{
                if(i<6){
                    res.push(item); 
                }
                return i
            })
        }  
        return { ...state, loading: false, results: res }
      case UPDATE_SELECTION:
        return { ...state, value: action.payload.selection }
      default:
        return {...state}
    }
  }