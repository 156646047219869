import React, { Component, createRef } from 'react';
//import axios from 'axios';
import { connect } from 'react-redux';

import { setModalStatus } from '../../actions'
import { ADMINFILES } from './../../constants/constants'
import { isMobile } from 'react-device-detect';
//import FavoriteContainer from '../itemsContainer/FavoriteContainer'


//import { isMobile,isTablet } from 'react-device-detect';
import CircularProgress from '@material-ui/core/CircularProgress'
import { Modal,  Segment, Grid } from 'semantic-ui-react'


class Extras extends Component {
  contextRef = createRef()
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      pass_name: '',
      pass_email: '',
      message: '',
      callback: "not fired",
      value: null,
      load: false,
      expired: "false",
      msg: '',
      msgContact: '',formError:0,formContError:0,
      page:'',ban:false

    }
    this._reCaptchaRef = React.createRef();

  }
  //----------------gOOGLE CAPCHA---------------------
  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, 1500);
    
    this.handleLoadPage();
  }


componentDidUpdate(prevProps, prevState) {
  if (prevProps.idEx !== this.props.idEx) {
   
    this.handleLoadPage();
    this.setState({ban:false})
  }
}
 
  ////////////////////////////////////////////////////

  
  renderProgress = () => {
    return <CircularProgress color="secondary" size={50} />;
  }
  handleLoadPage = () => {
    
   
     
    
      fetch(`${ADMINFILES}relatedScenes_v2.php?action=customPages&id=${this.props.idEx ? this.props.idEx : 2}`).then(
        data => (data.json()).then(
          
            page => {
              this.setState({ban:true, page: {__html:window.atob(page.Body)}});
              
            }
        )
    )
  }
   


  render() {
   // const { load } = this.state || {};
   
    //const {err} = this.props;
    
    
    return (
      <div>

        <Modal
          key={`modal_favorite`}
          open={(this.props.openExtras && this.props.ModalStatus) ? true : false}
          closeOnDocumentClick={true}
          position='top center'
          closeIcon
          size="large"
          onClose={this.props.setExtrasModalClose}
        >

          <Modal.Content >

            <Modal.Description >

              <Segment inverted style={{ marginBottom: '0', marginTop: '0', borderRadius: 0 }} >
                <Grid inverted centered padded divided='vertically'>
                  <Grid.Row centered>
                    <Grid.Column  width={(isMobile) ? '14' : '12'}  className="bg-contact">
                      <div dangerouslySetInnerHTML={this.state.page} />  
                    </Grid.Column>
                  </Grid.Row>

                </Grid>
              </Segment>




            </Modal.Description>
          </Modal.Content>
        </Modal></div>)
  }

}
const mapSetsToProps = state => ({
  UserConfiguration: state.setUserConfiguration
  , ModalStatus: state.setModalStatus.ModalStatus
});
const mapDispatchToPropsActions = dispatch => ({
  setModalStatus: value => dispatch(setModalStatus(value)),




});
export default connect(mapSetsToProps, mapDispatchToPropsActions)(Extras);


