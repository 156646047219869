//combinereducer
import { combineReducers } from 'redux';

import {zoomState} from './zoomState';
import {setModalStatus} from './setModalStatus';
import {setUserConfiguration} from './userConfiguration';
import {setsSiteController} from './setsSiteController';
import {setModelScenes} from './setModelScenes';
import {setLoadingMore} from './setLoadingMore';
import {setItmesContainer} from './setItmesContainer';
import {preLoadCorrds} from './preLoadCorrds';
import {itemsDisplayed} from './itemsDisplayed';
import {videoloaded} from './videoLoader';
import {loaderTransition} from './loader';
import {itemModalOpen} from './itemModalOpen';
import {resetView} from './resetView';
import {levelZoom} from './levelZoom';
import {getFavoriteScenes} from './getFavoriteScenes';
import {models} from './models';
import {searchManager} from './searchManager';
import {rideManager} from './rideManager';


export default combineReducers({
    zoomState,
    setModalStatus,
    setUserConfiguration,
    setsSiteController,
    setModelScenes,
    setLoadingMore,
    setItmesContainer,
    preLoadCorrds,
    itemsDisplayed,
    videoloaded,
    loaderTransition,
    itemModalOpen,
    resetView,
    levelZoom,
    getFavoriteScenes,
    models,
    searchManager,
    rideManager,
});
//Selectors


//sin reselect
export const getModalStatus = state => state.setModalStatus.ModalStatus;
export const getSetsLoaded= state => state.setsSiteController;
//export const getForecastDataCities = state => (_getForecastDataCities(state.cities, getCity(state)))
