//setsSiteController
import { SET_SETLIST } from './../actions'


export const setsSiteController = (state=null, action) => {
    
    switch (action.type) {
        case SET_SETLIST:
         return {...state,
            sets_data:action.payload.sets_data,
            }
      
        default:
           return {...state}
           
    }
  //  return state;
}