import React from 'react';


import { Image, Transition } from 'semantic-ui-react'
import { useInView } from 'react-intersection-observer';
//import { isMobile  } from 'react-device-detect';
import {  SITEURLCDN,PRCTOWHIDTH, INITPRCTOWHIDTH, } from './../../constants/constants';
import { getPorce, } from './../../services/functions'
import loading from './../holder/loading.jpg'
import {GenerateCDN} from './../hook/cdnHook'
const LazyImage = ({ width, height, scale, src, cdntime,zoomState, ...rest }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '500px 400px 500px 400px',
      
    });


    let pos = (scale !== undefined) ? scale : 3;
   
    let img;
   let  file = GenerateCDN(src, "/" + pos + ".jpg" , cdntime); 
    switch (pos) { //6 1 2 3
        case 1:
            img = SITEURLCDN + "" + src.Directory + "/" + pos + ".jpg" + file;
            img= img.split(' ').join('%20');
            break;
        case 2:
            img = SITEURLCDN + "" + src.Directory + "/" + pos + ".jpg" + file;
            img= img.split(' ').join('%20');
            break;
        case 3:
            img = SITEURLCDN + "" + src.Directory + "/" + pos + ".jpg" + file;
            img= img.split(' ').join('%20');
            break;
        case 6:
            img = SITEURLCDN + "" + src.Directory + "/" + pos + ".jpg" + file;
            img= img.split(' ').join('%20');
            break;
        default:
            img = SITEURLCDN + "" + src.Directory + "/" + pos + ".jpg" + file;
            img= img.split(' ').join('%20');
    }
    //rounded
    if(src.Directory===undefined){
        img=loading;
    }
    let MYscreenwidth = Math.round(parseInt(window.innerWidth * getPorce(zoomState)));
    let myHeight = MYscreenwidth / ((getPorce(zoomState) === 0.5) ? INITPRCTOWHIDTH : PRCTOWHIDTH);
    

    return (
        <div ref={ref} style={{ width: '300px',  minHeight: myHeight}}>
         
                <Transition
                    animation={'zoom'}
                    duration={900}
                    visible={inView} >

                    <Image
                        src={img}
                        className="setImage"
                        alt={src.title}
                        style={{ width: '100%', height: '100%' }}
                    />
 
                </Transition>
              
        </div>
 

    );
};

export default LazyImage;