import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import {store,persistor } from './store';
import Root from './Root';


ReactDOM.render(
  <Root store={store} persistor={persistor}/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//<React.StrictMode store={store}>
//<App />
//</React.StrictMode>
serviceWorker.register();

  