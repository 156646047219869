import md5 from 'md5';
import {CDNKEY} from './../../constants/constants'
export const GenerateCDN = (src, fcdn,cdntime,area='tour') => {
    //let pos = (scale !== undefined) ? scale : 3;

    //let img = SITEURL+""+src.Directory+"/"+pos+".jpg";
    let uri ="";
    if(area==="members"){
        uri = "/"+area+"/content/upload/" + src.Directory + "" + fcdn
    }else{
        uri = "/"+area+"/content/" + src.Directory + "" + fcdn
    }
    uri= uri.split(' ').join('%20'); 
    let file = '';
    let dirname = uri;//area+"/content/" + src.Directory;
    dirname= dirname.split(' ').join('%20');
    let urilen = dirname.length;
    
    let ttl = cdntime + (1 * 1 * 1 * 7200);
    let message = uri + "?expires=" + ttl + "&l=" + urilen + "&secret="+CDNKEY;
    let digest = md5(message);
    let token_value = "?expires=" + ttl + '&l=' + urilen + '&token=' + digest;
    file = file + token_value + '';
    return file;
}
