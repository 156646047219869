import { SET_ITEMSDISPLAYED } from '../actions'


export const itemsDisplayed = (state=null, action) => {
    
    switch (action.type) {
        case SET_ITEMSDISPLAYED:
          
         return {...state,
                    Items:action.payload.Items,
                    UsedCoords:action.payload.UsedCoords
             }
      
        default:
           return {...state}
           
    }
  
}
//export const getItemsDisplayed = (state,) => 