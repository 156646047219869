import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getModelScenes } from './../../actions'
import { SITEURLCDN } from './../../constants/constants';
import { GenerateCDN } from './../hook/cdnHook';


import CircularProgress from '@material-ui/core/CircularProgress'
import {
    Image, Card,Icon
} from 'semantic-ui-react'


class ItemsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openthisInternal: false,
            viewdisp: 'none',
            imageLoded: false,
            modelId: 0,
            readMore:true,
            txtrMore:'Read More',
            icon:'plus'
        }
    }
    toggleText=(key)=> {
        console.log(key)
    
        document.querySelector(".mi_"+key).classList.toggle("truncate");
        let estadoac=this.state.readMore;
        this.setState({icon:(this.state.readMore)?'minus':'plus',txtrMore:(!this.state.readMore)?'Read More':'Read Less',readMore:(estadoac)?false:true})
    }
    renderProgress = () => {
        return <CircularProgress color="secondary" size={50} />;
    }
    loadNewRelated = () => {
        // this.props.handleClose();

        let { set } = this.props;


        this.setState({ openthisInternal: true }, this.props.getModelScenes({ VideoID: set.Id }))
       
        if (this.props.setModalOpenRelated) {
            
            this.props.setModalOpenRelated();
        }
        // this.props.setloaderTransition({IsLoading:true});


    }
    setModalClose = () => {
        this.setState({ openthisInternal: false })

        this.props.setModalStatus({ ModalStatus: false });

    }
    render() {
        let { set, cdntime } = this.props;

        //cdntime
        let file = GenerateCDN(set, "/3.jpg", cdntime);
        let img = SITEURLCDN + "" + set.Directory + "/3.jpg" + file;
        img = img.split(' ').join('%20');
        //let img = SITEURL+""+set.Directory+"/3.jpg";
        return (

            <Card raised color='grey' style={{ marginTop: '0' }}  className={this.props.clname === 'new' ? 'noradius nomargin no-hover' : 'noradius no-hover'} >
                <Image src={img} className="noradius" loading="lazy"  onClick={this.loadNewRelated}/>

                <Card.Content className="nopadding noradius">
                    <Card.Header as='h5' className="nomargin">
                    <div style={{fontSize: "1.1rem"}} className={"mi_"+set.Id+" truncate"}> {set.title}</div>
                    {set.title.length>30?<span onClick={()=>this.toggleText(set.Id)} style={{ paddingLeft: '0px',fontSize: "0.9rem", cursor:'pointer'}}>{this.state.txtrMore}<Icon name={this.state.icon +' square outline'}  className="readMore"size='small' style={{ paddingLeft: '10px', cursor:'pointer'}} /></span>:''}
                </Card.Header>

                </Card.Content>

            </Card>

        )
    }
}
const mapDispatchToPropsActions = dispatch => ({

    getModelScenes: value => dispatch(getModelScenes(value)),


});
export default connect(null, mapDispatchToPropsActions)(ItemsContainer);
   // export default  ItemsContainer