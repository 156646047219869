import React, {  Component,  } from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-flexbox-grid';



import { setModalStatus, SetFavouriteScene, UnsetFavouriteScene,getModelScenes, } from '../actions'



import  PopupTriggerModal  from './helpers/PopupTriggerModal'

import CircularProgress from '@material-ui/core/CircularProgress'


import {InScreenView} from './hook/InScreenView'



const columns = {
    zoom1: 1,
    zoom2: 2,
    zoom3: 3,
    zoom4: 4,
    zoom5: 5,
    zoom6: 6,
    zoom7: 7,
    zoom8: 8,
    zoom9: 9,
    zoom10: 10,
    zoom11: 11,
    zoom12: 12,

}

class ItemCoords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openthis: false ,
            timerCounter:0,
            viewdisp:'none',
            imageLoded:false,
            modelId:0,
            imagedispplayed:0
        }
       

    }

    setModalOpen = () => {
        
       this.setState({ openthis: true })
       let  ItemID=this.props.setItem.Id;
     
       this.props.getModelScenes({ VideoID: ItemID })
     
       
    }
    setModalClose = () => {
      this.setState({ openthis: false })
        this.props.setModalStatus({ ModalStatus: false });

    }
    unfavourite = () => {
        if (this.props.isFav.length) {
            this.props.UnsetFavouriteScene({ sets_data: this.props.sets_data, ID: this.props.Id, Id: this.props.isFav[0].Id })
            
        }
    }
    favourite = () => {

        this.props.SetFavouriteScene({ sets_data: this.props.sets_data, ID: this.props.Id })
    }
    setIsImageLoaded=(v)=>{
        this.setState({imageLoded:v});
    }
    renderProgress = () => {
        return <CircularProgress color="secondary" size={50} />;
      }
   
   
    render() {
       let { divstyle,zoomstate,setItem,itemtoload }= this.props
        const _col = columns[zoomstate];
        
        return (
                
                <Col xs={_col} lg={_col} style={divstyle} key={`set_Col_${setItem.Id}`} className="fixspace" > 
                
                <InScreenView>
                
                    <PopupTriggerModal zoommstate={zoomstate} key={`modale_XX_${setItem.Id}`} setItem={setItem} itemtoload={itemtoload} openthis={this.state.openthis}  />
                </InScreenView>
                </Col>
            
        )

    }
}

const mapSetsToProps = state => ({ UserConfiguration: state.setUserConfiguration, sets_data: state.setsSiteController.sets_data,itemsHolder:state.setItmesContainer,related_scenes: state.setModelScenes.related_scenes,ModalStatus:state.setModalStatus.ModalStatus });
const mapDispatchToPropsActions = dispatch => ({
    setModalStatus: value => dispatch(setModalStatus(value)),
    SetFavouriteScene: value => dispatch(SetFavouriteScene(value)),
    UnsetFavouriteScene: value => dispatch(UnsetFavouriteScene(value)),
    getModelScenes: value => dispatch(getModelScenes(value)),
  

});
const ItemCoordsConnected = connect(mapSetsToProps, mapDispatchToPropsActions)(ItemCoords);

export default ItemCoordsConnected;