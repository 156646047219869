import { UPDATE_SELECTION } from '../actions';

export const  rideManager=(state=null, action)=> {
    switch (action.type) {
  
 
      case UPDATE_SELECTION:
        return { ...state, value: action.payload.value }
      default:
        return {...state}
    }
  }