import { SET_ITEMMODALOPEN } from '../actions'


export const itemModalOpen = (state=null, action) => {
    
    switch (action.type) {
        case SET_ITEMMODALOPEN:

         return {...state,
                    itemModal:action.payload.itemModal,
                    
             }
      
        default:
           return {...state}
           
    }
  //  return state; 
}