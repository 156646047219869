import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setNewUserConfiguration } from './../../actions'

import { Header, Button, Modal, Segment, Image, Grid } from 'semantic-ui-react'

import {   CAT1,CAT2,CAT3,CAT4 } from './../../constants/constants'

class ModalDefaultHome extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false }
    }

    handleHomeSelection = (home) =>{
        this.props.setNewUserConfiguration({action:'setHome',Home:home,zoommState: this.props.zoomState.zoommState, scale: this.props.scaleme,});
    }

    close = () => this.setState({ open: false })
    render() {
        let openModalHomeDefault = this.props.openModalHomeDefault;
        return (
            <Modal dimmer="blurring" open={openModalHomeDefault} onClose={this.close} >

                <Modal.Content>
                    <Modal.Description>
                        <Segment placeholder><Header inverted>Choise</Header>
                            <Grid columns={2} stackable textAlign='center'>
                                <Grid.Row verticalAlign='middle'>
                                   
                                    <Grid.Column style={{display: "inine"}}>
                                    <Image centered src="https://www.worldofmen.com/tour/content/southern_strokes/contenido/videos/southernstrokes/SS_SoloPhotos_RobbieDane/3.jpg" className="setImage circular  MuiPaper-elevation3 " alt="Twink" />
                                  
                                                <Button primary onClick={() => { this.handleHomeSelection(CAT1) }}> TWINKS</Button>
                                    </Grid.Column>
                                    <Grid.Column style={{display: "inine"}}>
                                    <Image centered src="https://www.worldofmen.com/tour/content/bring_me_a_boy/BMAB_AlexAxel_IsaacEsteban_ThankYouDaddy/3.jpg" className="setImage circular   MuiPaper-elevation3" alt="Hunk" />
                                       
                                                <Button primary onClick={() => { this.handleHomeSelection(CAT2) }}>DADDY / BOYS</Button>
                                    </Grid.Column>
                                    <Grid.Column style={{display: "inine"}}>
                                    <Image centered src="https://www.worldofmen.com/tour/content/breed_me_raw/contenido/videos/breedmeraw/BMR_RomeoDavis_FelixODair/3.jpg" className="setImage circular   MuiPaper-elevation3" alt="Hunk" />
                                       
                                                <Button primary onClick={() => { this.handleHomeSelection(CAT3) }}>HUNKS</Button>
                                    </Grid.Column>
                                    <Grid.Column style={{display: "inine"}}>
                                    <Image centered src="https://www.worldofmen.com/tour/content/bear_films/bearfilms/bearfilms/videos/BF_FuckMeZeke/3.jpg" className="setImage circular   MuiPaper-elevation3" alt="Hunk" />
                                                <Button primary onClick={() => { this.handleHomeSelection(CAT4) }}>BEARS</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>

                    </Modal.Description>
                </Modal.Content>

            </Modal>)



    }
}
//export default ModalDefaultHome;
const mapSetsToProps = state => ({
     zoomState: state.zoomState
  }) 
const mapDispatchToPropsActions = dispatch => ({
    setNewUserConfiguration: value => dispatch(setNewUserConfiguration(value))
  });
const ModalDefaultHomeConnected = connect(mapSetsToProps, mapDispatchToPropsActions)(ModalDefaultHome);
export default ModalDefaultHomeConnected;