import { SET_MODELSCENES } from '../actions'


export const setModelScenes = (state=null, action) => {
    
    switch (action.type) {
        case SET_MODELSCENES:
          
         return {...state,
            related_scenes:action.payload.related_scenes,
            }
      
        default:
           return {...state}
           
    }
  //  return state;
}