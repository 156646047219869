
import { SET_MODALSTATE } from './../actions'
const ModalStatus=false;

export const setModalStatus = (state=null, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case SET_MODALSTATE:
         return {...state,
                ModalStatus:action.payload.ModalStatus,
                
            }
      
        default:
           return {...state,ModalStatus}
           
    }
  //  return state;
}