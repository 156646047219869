import React, {  Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row } from 'react-flexbox-grid';
import { getSetsConfiguration,setresetView,rideManager } from './../actions'
import ItemCoords from './ItemCoords'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isMobile } from 'react-device-detect';
import {ZOOMPC,ZOOMMOBILE} from './../constants/constants'
class LoaderItemsbyCoords extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstState: 0,
      zoomstate: null,
      sets_data: null,
      procentaje: 0,
      alreadyShowed: [],
      showProgress: false,
    };

  }

  componentDidMount() {
    this.getsets();

   /* if (!this.state.firstState) {
      this.setState({ firstState: (this.props.Items &&  this.state.firstState !=0 ) ? this.props.Items.length : 0 });
    }*/
  }

  getsets = () => {
    const sets = [];
    this.setState({ alreadyShowed: sets });
    return sets;
  }




  startModal = () => {
    if (this.state.showProgress) {
      this.setState({ showProgress: false })
    } else {
      this.setState({ showProgress: true })
    }

  }
  renderProgress = () => {
    return <CircularProgress color="secondary" size={50} />;
  }
  renderCoords = () => {
    
    
    return (this.props.Items) ? this.props.Items.map((item, i) => {
      let setItem = [];
      let theReturn;
     // if (this.props.sets_data.length > i) {
      if (this.props.Items.length > i ) {
        
        setItem = item[1];
        if(i===0 && !this.props.rideValue){//posiTo+2 
          this.props.rideManager({value:setItem}) ;
         
       }
        theReturn = <ItemCoords divstyle={item[0]} setItem={setItem} itemtoload={i} zoomstate={this.props.zoomState.zoommState} key={`mirow${i}`} /> 
        
      }
      return theReturn;
    }) : '';
    
  }
  render() {
    const { restoreview } = this.props.zoomState;
    const { Items } = this.props;
    const { positionX, positionY,scale,IsresetingView } = this.props;


    if (restoreview) {
      
      this.props.restore();
      this.props.funf({positionX:positionX, positionY:positionY,scale:scale})
    }
  
    if (IsresetingView) {
  
      if (IsresetingView[0]) {
        let scale=ZOOMPC;
        if(isMobile){
             scale=ZOOMMOBILE;
            
        }      
       this.props.setTransform(0, 0, scale, 125, 'easeInQuad');
      }
    }

    return (
      <div className="" style={{ width: '100%', height: '100vh' }}>

        <Grid fluid >
          <Row start="lg" >

            {this.props.sets_data && Items ? this.renderCoords() : this.renderProgress()}

          </Row>
        </Grid>
      </div>
    );
  }


}


const mapSetsToProps = state => ({
  zoomState: state.zoomState
  , UserConfiguration: state.setUserConfiguration
  , sets_data: state.setsSiteController.sets_data
  , coords: state.preLoadCorrds
  , rideValue: state.rideManager.value
  , Items: state.itemsDisplayed.Items
  ,IsresetingView: state.resetView.IsresetingView
});
const mapDispatchToPropsActions = dispatch => ({

  getSetsConfiguration: value => dispatch(getSetsConfiguration(value)),
  setresetView: value => dispatch(setresetView(value)),
  rideManager: value => dispatch(rideManager(value)),

});
export default connect(mapSetsToProps, mapDispatchToPropsActions)(LoaderItemsbyCoords);