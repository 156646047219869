
import { getPorce, } from './functions'

import {PRCTOWHIDTH} from './../constants/constants';
const actualZoomState=(window.innerWidth<600)?'zoom12':'zoom6';
const MYscreenwidth = Math.round(parseInt( window.innerWidth * getPorce(actualZoomState)));
const d = new Date();
let myHeight = MYscreenwidth/PRCTOWHIDTH;

    //myHeight =MYscreenwidth/1.5;


export const zoomState= {
    
    value: 0,
    zoommState: actualZoomState,
    cdntime:  d.getTime(),
    currentW: MYscreenwidth,
    currentH: myHeight,
    
}
export const stateGetStyles=(x,y,payload,push=false)=>{
    
    //let itemsStyles = [];


    /*let { colly, fill_x, ctoporX, ctoporY, coordxtodrow } = drawPosition(x,
        payload.yDir,
        payload.ystarton,
        payload.xstarton,
        payload.xDir,
      getPorce(payload.zoommState),
      payload.ultimayt,
      payload.ultimayd,
      payload.ultimaxr,
      payload.distancia,
      payload.xsEndon,
      payload.ultimaxl,
      payload.yEndon,
      payload.distanciaY, window.innerWidth, window.innerHeight, payload.zoomCantY, payload.zoommState);



    if (payload.ultimaxr === 0) {
        payload.ultimaxr=ctoporX;  
        payload.ultimayd= ctoporY;  
        payload.zoomCantX= ctoporX; 
        payload.zoomCantY= ctoporY;
    } 
    if (payload.zoomCantY === 0 || payload.zoomCantY !== ctoporY) {
     
      payload.zoomCantX= ctoporX;  payload.zoomCantY = ctoporY;
    }*/

  //  itemsStyles = trnsalteCoords(x, y, ctoporX, ctoporY, getPorce(payload.zoommState), fill_x, colly, coordxtodrow, payload.ultimaxr, payload.ultimaxl, payload.xDir, payload.ystarton, payload.procentaje, payload.zoommState)
    /*if(!push){
        payload.itemsStyles=itemsStyles;
    }else{
        let oldstate = payload.itemsStyles;
        let stylesnew = itemsStyles;
        stylesnew.map((item, i) => oldstate.push(item));
        payload.itemsStyles=oldstate;
       
    }*/
    return payload;
}
export const _handlePanningintoState=(payload,posX,posY)=>{
     
    if (posX > 0) {
        payload.xDir= 1;
        
    } else {
        payload.xDir= -1;
        
    }
    if (posY > 0) {
        payload.yDir= 1 ; //sube
       
    } else {
        payload.yDir= -1 ;//baja
        
    }

    let sumaH = parseInt(((payload.ultimayt ) * payload.currentH));
    
    if (sumaH === 0) {
      sumaH = 1; //payload.currentH;
    }
  //  console.log(posY+" > "+(sumaH));
    if (posY > (sumaH)) {
        
        payload.xDir = 0;
        payload.ystarton= (( payload.ultimayt + 1) * payload.currentH);
        payload.ultimayt= (payload.ultimayt + 1);
        payload.panningendX= 0;
        payload.panningendY= sumaH;
        payload.pushintostate=true;
        payload.panningBlock=true
        payload.lockAxisX=true;
        payload.lockAxisY=false;
        
    } else {

      let sumaH2 = parseInt(((payload.ultimayd) * payload.currentH));
        if (sumaH2 === 0) {
             sumaH2 = 1 ;//payload.currentH;
        }
     
      if ((window.innerHeight + (posY * -1)) > sumaH2) {
            payload.xDir= 0;
            payload.distanciaY = posY;
            payload.ultimayd = (payload.ultimayd+ 1);
            payload.yEndon=sumaH2;
            payload.panningendX= 0;
            payload.panningendY= sumaH ;
            payload.pushintostate=true;
            payload.panningBlock=true
            payload.lockAxisX=true;
            payload.lockAxisY=false;
    
      } else {

        let Suma = parseInt((payload.ultimaxl * payload.currentW));
        if (Suma === 0) {
            Suma = 1;//payload.currentW;
        }

        if (posX > 0 && posX > Suma) {
            payload.ultimaxl=  payload.ultimaxl+1;
            payload.xstarton=  payload.ultimaxl * payload.currentW;
            payload.panningendX= (payload.ultimaxl * payload.currentW);
            payload.panningendY= 0 ;
            payload.pushintostate=true;
            payload.panningBlock=true

            payload.lockAxisX=false;
            payload.lockAxisY=true;
        }

        let Suma2 = parseInt((payload.ultimaxr * payload.currentW));
        if (Suma2 === 0) {
          Suma2 = 1;//payload.currentW;
        }
        
        if ((window.innerWidth + (posX * -1)) > Suma2) {
            payload.distancia= posX;
            payload.ultimaxr=payload.ultimaxr+1;
            payload.xsEndon=Suma2;
            payload.panningendX= Suma2;
            payload.panningendY= 0 ;
            payload.pushintostate=true;
            payload.panningBlock=true
         
            payload.lockAxisX=false;
            payload.lockAxisY=true;


        }
      }
    }
    payload.panningBlock=false;
    
    return payload;
}
