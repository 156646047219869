/* eslint-disable react/jsx-no-duplicate-props */
import ReactPlayer from 'react-player'
import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getModelScenes, setItmesContainer } from './../actions/'
import { Icon, Button } from 'semantic-ui-react'
import './layout.css';
import "./../components/video/Video.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { ModelPageInfo } from './../components/helpers/ModelPageInfo'
import { SITEURLCDN, SITEMEMBERURLCDN,ADMINFILES } from './../constants/constants';
import { GenerateCDN } from './../components/hook/cdnHook';

class VideoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleShow = () => this.setState({ active: true })
  handleHide = () => this.setState({ active: false })
  player = {}

  handleClick = () => {

    const { history } = this.props;


    if (history)
      history.push('/HomePage');
  }

  onPlayerReady(player) {
    console.log("Player is ready: ", player);
    this.player = player;
  }

  onVideoPlay(duration) {
    console.log("Video played at: ", duration);
  }

  onVideoPause(duration) {
    console.log("Video paused at: ", duration);
  }

  onVideoTimeUpdate(duration) {
    console.log("Time updated: ", duration);
  }

  onVideoSeeking(duration) {
    console.log("Video seeking: ", duration);
  }

  onVideoSeeked(from, to) {
    console.log(`Video seeked from ${from} to ${to}`);
  }

  onVideoEnd() {
    console.log("Video ended");
  }

  handlelevelOne = () => {
    this.props.handleClose()
  }
  handleTraking(){
    let {myItem,category,UserConfiguration} =this.props;
    const modelSceneUri = `${ADMINFILES}/memberInfo.php?`;
    const url_api = `${modelSceneUri}Action=tracking_video&video_id=${myItem.Id}&title=${myItem.title}&category=${category}&UserName=${UserConfiguration.UserName}&rand=${Math.random()}`;
    axios({
        method: "GET",
        url: `${url_api}`,
    }).then((response) => {
        console.log("Data",response)
    }).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
    });
}
  handleModelsSearchModal = () => {

    this.props.searchsetModalOpenRelated(true);


  }
  render() {

    let { videoItem, vid_gal, modelsSelected } = this.props;

    let images = [];
    if (vid_gal === 1 && videoItem[1].length === 0) {
      vid_gal = 2;
    }
    if (vid_gal === 2) {

      videoItem[0].map((itemphoto) => {
        let filePHOTO = GenerateCDN(itemphoto, itemphoto.PHOTO, this.props.cdntime, 'members');
        let fileTHUMB = GenerateCDN(itemphoto, itemphoto.image, this.props.cdntime, 'members');
        let POTHOFile = SITEMEMBERURLCDN + "" + itemphoto.Directory + itemphoto.PHOTO + filePHOTO;
        let THUMBFile = SITEMEMBERURLCDN + "" + itemphoto.Directory + itemphoto.image + fileTHUMB;
        POTHOFile = POTHOFile.split(' ').join('%20');
        THUMBFile = THUMBFile.split(' ').join('%20');

        images.push({ original: POTHOFile, thumbnail: THUMBFile });

        return '';
      }

      )


    }
    let object;
    if (vid_gal === 3) {
      videoItem[2].map((item) => {
        if (item[0].Id === modelsSelected) {
          object = item[0];
        }
        return 0;
      })


    }
    if (vid_gal === 4 && modelsSelected.length > 0) {
      object = modelsSelected[0];

      //console.log(object);
    }
    let videoFiles = '';
    let videopreview = '';
    if (videoItem.length > 0) {
      if (videoItem[1].length > 0) {
        let filevideo = GenerateCDN(videoItem[1][0], videoItem[1][0].video, this.props.cdntime, 'members');
        videoFiles = SITEMEMBERURLCDN + "" + videoItem[1][0].Directory + videoItem[1][0].video + filevideo;
        videoFiles = videoFiles.split(' ').join('%20');

        let file = GenerateCDN(videoItem[1][0], videoItem[1][0].image, this.props.cdntime);
        videopreview = SITEURLCDN + "" + videoItem[1][0].Directory + videoItem[1][0].image + file;
        videopreview = videopreview.split(' ').join('%20');
      }
    }
    return (

      <div>
        {(vid_gal === 1) ?
          // Video Page
          <div className='player-wrapper full_vid'>

            <ReactPlayer
              muted={false}
              playing
              playIcon={<Button color='black' size='massive' onClick={()=> this.handleTraking()} circular icon inverted > <Icon name='play' /></Button>}
              light={videopreview}
              playsinline={true}
              pip={false}
              config={{ file: { attributes: { disablepictureinpicture: 'true', controlsList: 'nodownload' } } }}
              className='react-player '
              url={videoFiles} controls
              width='100%'

              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                    disablepictureinpicture: 'true',
                    onContextMenu: e => e.preventDefault()
                  }
                }
              }}
            />
          </div>
          : vid_gal === 2 ?
            // Gallery Page
            <div> <ImageGallery lazyLoad={true} showPlayButton={false} showFullscreenButton={false} items={images} /> </div>
            // Model Page
            : vid_gal === 3 ?
              <div> <ModelPageInfo model={object} handlelevelOne={this.handlelevelOne} cdntime={this.props.cdntime} /></div>
              :
              <div> <ModelPageInfo setModalOpenRelated={this.handleModelsSearchModal} model={object} handlelevelOne={this.handlelevelOne} cdntime={this.props.cdntime} /></div>
        }

      </div>

    )
  }
}
const mapSetsToProps = state => ({ 
  UserConfiguration: state.setUserConfiguration,
  related_scenes: state.setModelScenes.related_scenes, itemsHolder: state.setItmesContainer });
const mapDispatchToPropsActions = dispatch => ({
  getModelScenes: value => dispatch(getModelScenes(value)),
  setItmesContainer: value => dispatch(setItmesContainer(value))

});
export default connect(mapSetsToProps, mapDispatchToPropsActions)(VideoPage)
//<fGrid fluid style={{ display: 'none' }}>
//<Row start="lg">
  //{related_scenes ? this.renderSetDataItem() : this.renderProgress()}
//</Row>
//</fGrid>