import { styleme, getPorce } from '../services/functions'
import { CAT1, CAT2, CAT3, CAT4, MENS, STUDIOS } from './../constants/constants'
import _ from 'lodash'


export const CoordsToSets_act = (clickx, clicky, coords, panX, panY, alreadyAdded, zoommState, scale, sets_data, misetcargados) => {
  
  //console.log("CoordTosets, La info de pos",objPos);
  if (scale < 1) {
    scale = 1;
  } else {
    //scale = scale - 0.5
    if (scale < 1) {
      scale = 1;
    }
  }
  let cat71 = sets_data[0];
  let cat75 = sets_data[1];
  let cat140 = sets_data[2];
  let cat160 = sets_data[3];

  let MYscreenwidth = Math.round(parseInt(window.innerWidth * getPorce(zoommState)));


  let setItems = [];
  let agregar = true
  let sumpx = (zoommState === 'zoom1' || zoommState === 'zoom2' || zoommState === 'zoom4') ? MYscreenwidth + 60 : MYscreenwidth + 60;



  let x0 = panX;
  let y0 = panY;
  let xbase = 0;
  let yaltura = 0

  if (scale !== 1) {
    x0 = panX * -1;
    y0 = panY * -1;
    xbase = ((x0 + (window.innerWidth * scale)) / scale)+ sumpx * 2;
    yaltura = ((y0 + (window.innerHeight * scale)) / scale)+ sumpx * 2;
    x0 = Math.round(x0 / scale);
    y0 = Math.round(y0 / scale);
   

  }


  //console.log("antes de las operaciones = " + x0 , y0 , scale)
  if ((panX !== 0 && panY !== 0)) {
    if (scale === 1) {
      x0 = (x0 < 0) ? x0 - sumpx * 2 : x0 + (sumpx + sumpx / 2);
      y0 = (y0 < 0) ? y0 - sumpx : y0 + (sumpx + sumpx / 2);
    } else {
      x0 = x0 - (sumpx + sumpx / 2); //( x0<0)? x0 - sumpx*2 : x0 - (sumpx + sumpx/2) ;
      y0 = y0 - (sumpx + sumpx / 2) //( y0<0)? y0 - sumpx*2 : y0 - (sumpx + sumpx/2)  ;
    }
  }
  if (scale === 1) {
    x0 = x0 * -1;
    y0 = y0 * -1;
  }
  if (x0 === -0) {
    x0 = 0;
  }
  if (y0 === -0) {
    y0 = 0;
  }
  //console.log(x0,y0);
  if (scale === 1) {


    xbase = ((x0 + (window.innerWidth * scale)) / scale) + sumpx * 2;
    yaltura = ((y0 + (window.innerHeight * scale)) / scale) + sumpx * 2;
  }

 




  ////////////////////////////////////////////////////////////////////////////
  //if ((parseInt(item[0]) >= (x0  - (window.innerWidth*4/5)) && parseInt(item[0]) <=  base) && (parseInt(item[1]) >= (y0 - (window.innerHeight*4/5)) && item[1] <= (altura + y0))) {
  ////////////////////////////////////////////////////////////////////////////
 

  let myarr = [];
  const isMatch = (item) => {
    if (scale > 1) {
      if ((parseInt(item[0]) >= x0 && parseInt(item[0]) <= xbase) && (parseInt(item[1]) >= y0 && item[1] <= yaltura)) {
        return true;
      } else {
        return false;
      }
    } else {
      if ((parseInt(item[0]) >= (x0 - (window.innerWidth * 3 / 5)) && parseInt(item[0]) <= xbase) && (parseInt(item[1]) >= (y0 - (window.innerHeight * 3 / 5)) && item[1] <= (yaltura ))) {
        return true;
      } else {
        return false;
      }
    }
  }

  let mytemp = _.filter(coords.splets[0], isMatch);
  mytemp.map((item120) => { myarr.push(item120); return "fed" })
  mytemp = _.filter(coords.splets[1], isMatch);
  mytemp.map((item120) => { myarr.push(item120); return "fed" })
  mytemp = _.filter(coords.splets[2], isMatch);
  mytemp.map((item120) => { myarr.push(item120); return "fed" })
  mytemp = _.filter(coords.splets[3], isMatch);
  mytemp.map((item120) => { myarr.push(item120); return "fed" })
  /* 
  coords.splets[0].map((item120) => { myarr.push(item120); return "fed" })
  coords.splets[1].map((item13) => { myarr.push(item13); return "fed" })
  coords.splets[2].map((item14) => { myarr.push(item14); return "fed"  })
  coords.splets[3].map((item15) => { myarr.push(item15); return "fed" })*/


  //  coords.coordenadas.map((item) => {
  myarr.map((item) => {




    // if ((parseInt(item[0]) >= x0 && parseInt(item[0]) <=  base) && (parseInt(item[1]) >= y0 && item[1] <= altura)) {
    agregar = true
    for (let x = 0; x < alreadyAdded.length; x++) {
      if (alreadyAdded[x] === item[0] + "," + item[1]) {
        agregar = false;
      }
    }
    if (agregar) {

      let divstyle = styleme(item[0], item[1]);
      alreadyAdded.push(item[0] + "," + item[1]);
      // 08-09-2020
      let Myset = '';
      if (item[2] === CAT1) {
        for (let f = 0; f < cat71.length; f++) {
          Myset = '';
          if (misetcargados.length > 0) {
            let toaddthis = true;

            for (let j = 0; j < misetcargados.length; j++) {
              let id1 = cat71[f].Id;
              let id2 = misetcargados[j][1].Id;
              if (id1 === id2) {
                toaddthis = false;

                break;
              }
            }
            if (toaddthis) {
              Myset = cat71[f];

              break;
            }
          } else {
            Myset = cat71[f];
            break;
          }
          //  console.log(item[2]);

        }
        if (typeof Myset != 'object') {
          Myset = cat71[Math.floor(Math.random() * cat71.length)];
        }
      }
      if (item[2] === CAT2) {
        for (let f = 0; f < cat75.length; f++) {

          if (misetcargados.length > 0) {
            let toaddthis = true;
            for (let j = 0; j < misetcargados.length; j++) {
              let id1 = cat75[f].Id;
              let id2 = misetcargados[j][1].Id;
              if (id1 === id2) {
                toaddthis = false;

                break;
              }
            }
            if (toaddthis) {
              Myset = cat75[f];

              break;
            }
          } else {
            Myset = cat75[f];
            break;
          }
          //  console.log(item[2]);

        }
        if (typeof Myset != 'object') {
          Myset = cat75[Math.floor(Math.random() * cat75.length)];
        }
      }
      if (item[2] === CAT3) {
        for (let f = 0; f < cat140.length; f++) {

          if (misetcargados.length > 0) {
            let toaddthis = true;
            for (let j = 0; j < misetcargados.length; j++) {
              let id1 = cat140[f].Id;
              let id2 = misetcargados[j][1].Id;
              if (id1 === id2) {
                toaddthis = false;

                break;
              }
            }
            if (toaddthis) {
              Myset = cat140[f];

              break;
            }
          } else {
            Myset = cat140[f];
            break;
          }
          //  console.log(item[2]);

        }
        if (typeof Myset != 'object') {
          Myset = cat140[Math.floor(Math.random() * cat140.length)];
        }
      }
      if (item[2] === CAT4) {
        for (let f = 0; f < cat160.length; f++) {

          if (misetcargados.length > 0) {
            let toaddthis = true;
            for (let j = 0; j < misetcargados.length; j++) {
              let id1 = cat160[f].Id;
              let id2 = misetcargados[j][1].Id;
              if (id1 === id2) {
                toaddthis = false;

                break;
              }
            }

            if (toaddthis) {
              Myset = cat160[f];

              break;
            }
          } else {
            Myset = cat160[f];
            break;
          }
          //  console.log(item[2]);

        }
        if (typeof Myset != 'object') {
          Myset = cat160[Math.floor(Math.random() * cat160.length)];
        }
      }

     

      let obj = [divstyle, Myset, [item[0], item[1]]]
      //misetcargados

      misetcargados.push(obj);
      setItems.push(obj);
      // i++;
    }
    // }



    return null;
    //} //while
  }); ///map




  let result = [setItems, alreadyAdded];
  return result
}

export const ResetImagesToSets = (panX, panY, zoommState, scale, previousScale, items) => {

  if (scale < 1) {
    scale = 1;
  }
  let MYscreenwidth = Math.round(parseInt(window.innerWidth * getPorce(zoommState)));


  let setItems = [];
  let hayCambios = false;
  let sumpx = (zoommState === 'zoom1' || zoommState === 'zoom6') ? MYscreenwidth : 500;

  let x0 = panX;
  let y0 = panY;
  /* if (scale !== 1) {
     x0 = panX * -1;
     y0 = panY * -1;
 
     x0 = Math.round(x0 / scale);
     y0 = Math.round(y0 / scale);
   }*/
  let xbase = 0;
  let yaltura = 0
 
  if (scale !== 1) {
    x0 = panX * -1;
    y0 = panY * -1;
    xbase = (x0 + (window.innerWidth * scale)) / scale;
    yaltura = (y0 + (window.innerHeight * scale)) / scale;
    x0 = Math.round(x0 / scale);
    y0 = Math.round(y0 / scale);


  }
  //console.log("antes de las operaciones = " + x0 , y0 , scale)
  if ((panX !== 0 && panY !== 0)) {
    if (scale === 1) {
      x0 = (x0 < 0) ? x0 + sumpx * 1 : x0 + (sumpx + sumpx / 2);
      y0 = (y0 < 0) ? y0 + sumpx : y0 + (sumpx + sumpx / 2);
    } else {
      x0 = (x0 < 0) ? x0 - sumpx : x0 - (sumpx + sumpx / 2);
      y0 = (y0 < 0) ? y0 - sumpx : y0 - sumpx;
    }
  }
  if (scale === 1) {
    x0 = x0 * -1;
    y0 = y0 * -1;
  }

   xbase = window.innerWidth + sumpx + sumpx / 2;
   yaltura = window.innerHeight + sumpx + sumpx / 2;

  //console.log("luego de las operaciones paniing = " + x0, y0, scale)


  //let myarr = [];
  const isMatch = (item) => {
    if (scale > 1) {
      if ((parseInt(item[0]) >= x0 && parseInt(item[0]) <= xbase) && (parseInt(item[1]) >= y0 && item[1] <= yaltura)) {
        return true;
      } else {
        return false;
      }
    } else {
      if ((parseInt(item[0]) >= (x0 - (window.innerWidth * 3 / 5)) && parseInt(item[0]) <= xbase) && (parseInt(item[1]) >= (y0 - (window.innerHeight * 3 / 5)) && item[1] <= (yaltura ))) {
        return true;
      } else {
        return false;
      }
    }
  }

  let mytemp = _.filter(items, isMatch);

  mytemp.map((olditem) => {
    let item = olditem;
    //Items[i][1]

    //if ((parseInt(item[2][0]) >= x0 && parseInt(item[2][0]) <= (x0 + base)) && (parseInt(item[2][1]) >= y0 && item[2][1] <= y0 + altura)) {
    if ((parseInt(item[2][0]) >= x0 && parseInt(item[2][0]) <= (xbase)) && (parseInt(item[2][1]) >= y0 && item[2][1] <= yaltura)) {


      if (scale > 4) {
        item[1].ShowedImage = item[1].Thumb7;

      } else {
        if (4 > scale && scale > 3) {
          item[1].ShowedImage = item[1].Thumb2;
        } else {
          if (3 > scale && scale > 2) {
            item[1].ShowedImage = item[1].Thumb3;
          } else {
            if (2 > scale && scale > 1) {
              item[1].ShowedImage = item[1].Thumb6;
            } else {
              item[1].ShowedImage = item[1].Thumb6;
            }
          }
        }
      }


      setItems.push(item);
      hayCambios = true;
    } else {
      hayCambios = true;
      setItems.push(item);
    }



    return null;
  });



  if (hayCambios) {
    return setItems;
  } else {
    return [];
  }


}

/******************************NUEVO CODIGO PARA LAS CATEGORIAS************************************************ */
export const CoordsToSets = (clickx, clicky, coords, panX, panY, alreadyAdded, zoommState, scale, sets_data, misetcargados) => {


  if (scale < 1) {
    scale = 1;
  } else {

    if (scale < 1) {
      scale = 1;
    }
  }

  //-------------------------
  let cat71 = sets_data[0];
  let cat75 = sets_data[1];
  let cat140 = sets_data[2];
  let cat160 = sets_data[3];
  //-------------------------
  let MYscreenwidth = Math.round(parseInt(window.innerWidth * getPorce(zoommState)));
  let setItems = [];
  let agregar = true
  let sumpx = (zoommState === 'zoom1' || zoommState === 'zoom2' || zoommState === 'zoom4') ? MYscreenwidth + 60 : MYscreenwidth + 60;
  let x0 = panX;
  let y0 = panY;
  let xbase = 0;
  let yaltura = 0

  if (scale !== 1) {
    x0 = panX * -1;
    y0 = panY * -1;
    xbase = ((x0 + (window.innerWidth * scale)) / scale) + sumpx * 2;
    yaltura = ((y0 + (window.innerHeight * scale)) / scale) + sumpx * 2;
    x0 = Math.round(x0 / scale);
    y0 = Math.round(y0 / scale);


  }


  //console.log("antes de las operaciones = " + x0 , y0 , scale)
  if ((panX !== 0 && panY !== 0)) {
    if (scale === 1) {
      x0 = (x0 < 0) ? x0 - sumpx * 2 : x0 + (sumpx + sumpx / 2);
      y0 = (y0 < 0) ? y0 - sumpx : y0 + (sumpx + sumpx / 2);
    } else {
      x0 = x0 - (sumpx + sumpx / 2); //( x0<0)? x0 - sumpx*2 : x0 - (sumpx + sumpx/2) ;
      y0 = y0 - (sumpx + sumpx / 2) //( y0<0)? y0 - sumpx*2 : y0 - (sumpx + sumpx/2)  ;
    }
  }
  if (scale === 1) {
    x0 = x0 * -1;
    y0 = y0 * -1;
  }
  if (x0 === -0) {
    x0 = 0;
  }
  if (y0 === -0) {
    y0 = 0;
  }
  //console.log(x0,y0);
  if (scale === 1) {


    xbase = ((x0 + (window.innerWidth * scale)) / scale) + sumpx * 2;
    yaltura = ((y0 + (window.innerHeight * scale)) / scale) + sumpx * 2;
  }

  let myarr = [];
  const isMatch = (item) => {
    if (scale > 1) {
      if ((parseInt(item[0]) >= x0 && parseInt(item[0]) <= xbase) && (parseInt(item[1]) >= y0 && item[1] <= yaltura)) {
        return true;
      } else {
        return false;
      }
    } else {
      if ((parseInt(item[0]) >= (x0 - (window.innerWidth * 3 / 5)) && parseInt(item[0]) <= xbase) && (parseInt(item[1]) >= (y0 - (window.innerHeight * 3 / 5)) && item[1] <= (yaltura))) {
        return true;
      } else {
        return false;
      }
    }
  }

  let mytemp = _.filter(coords.splets[0], isMatch);
  mytemp.map((item120) => { myarr.push(item120); return "fed" })
  mytemp = _.filter(coords.splets[1], isMatch);
  mytemp.map((item120) => { myarr.push(item120); return "fed" })
  mytemp = _.filter(coords.splets[2], isMatch);
  mytemp.map((item120) => { myarr.push(item120); return "fed" })
  mytemp = _.filter(coords.splets[3], isMatch);
  mytemp.map((item120) => { myarr.push(item120); return "fed" })

  myarr.map((item) => {

    agregar = true
    for (let x = 0; x < alreadyAdded.length; x++) {
      if (alreadyAdded[x] === item[0] + "," + item[1]) {
        agregar = false;
      }
    }
    if (agregar) {

      let divstyle = styleme(item[0], item[1]);
      alreadyAdded.push(item[0] + "," + item[1]);

      let Myset = '';
      /* ********************************** */
     
      let categories = [];
      if (STUDIOS.includes( item[2])) {
        categories = STUDIOS;
      } else {
        categories = MENS;
      }
      let idx = categories.indexOf(item[2]);
      categories.forEach(element => {
        if (item[2] === element) {
          for (let f = 0; f < sets_data[idx].length; f++) {
            Myset = '';
            if (misetcargados.length > 0) {
              let toaddthis = true;
  
              for (let j = 0; j < misetcargados.length; j++) {
                let id1 = sets_data[idx][f].Id;
                let id2 = misetcargados[j][1].Id;
                if (id1 === id2) {
                  toaddthis = false;
  
                  break;
                }
              }
              if (toaddthis) {
                Myset = sets_data[idx][f];
  
                break;
              }
            } else {
              Myset = sets_data[idx][f];
              break;
            }
            //  console.log(item[2]);
  
          }
          if (typeof Myset != 'object') {
            Myset = sets_data[idx][Math.floor(Math.random() * sets_data[idx].length)];
          }
          let obj = [divstyle, Myset, [item[0], item[1]]]
          //misetcargados
    
          misetcargados.push(obj);
          setItems.push(obj);
        }
      });
      /* ********************************** */
     


    
    
    }
    



    return null;
    
  }); ///map




  let result = [setItems, alreadyAdded];
  return result
}