import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';

import { setModalStatus, SetFavouriteScene, UnsetFavouritem, getModelScenes, } from '../../actions'


import FavoriteContainer from '../itemsContainer/FavoriteContainer'


import { isMobile,isTablet } from 'react-device-detect';
import CircularProgress from '@material-ui/core/CircularProgress'
import {Modal, Grid, Header,  Segment, Card, } from 'semantic-ui-react'


class Favorites extends Component {
    contextRef = createRef()
    constructor(props) {
        super(props);
        this.state = {
            openthis: false,
            viewdisp: 'none',
            imageLoded: false,
            modelId: 0,
            _related_scenes:[],
            _item:[],
            showEffect:false
        }
    }
    componentDidMount(){
        this.setState({showEffect: true} )
    }
    renderProgress = () => {
        return <CircularProgress color="secondary" size={50} />;
    }
    setModalOpen = (v) => {
        
        this.setState({ openthis: true })
        
        this.props.getModelScenes({ VideoID: v })
        const { favorite_scenes } = this.props;
        if(favorite_scenes){
            favorite_scenes.map( (item,i) =>{ if(i===0){this.setState({ _item:item}) ;} return null;})
            this.setState({ _related_scenes:favorite_scenes})
        }
     
                
    }
   
    unfavourite = (Id) => {
            this.props.UnsetFavouritem({ favorite_scenes: this.props.favorite_scenes, ID: Id })
            this.setState({ openthis: true })
            this.props.handleFavoritemodal();
    }
    favourite = (Id) => {

        this.props.SetFavouriteScene({ sets_data: this.props.favorite_scenes, ID: Id })
        
    }
    setIsImageLoaded = (v) => {
        this.setState({ imageLoded: v });
    }
    render() {
       // let image;
    
        const { favorite_scenes, } = this.props;
       
        const { zoommState } = this.props.zoomState;
        
       
        

      
       
        return (
           <div> 
           
                <Modal 
                    key={`modal_favorite`}
                    open={(this.props.openthis && this.props.ModalStatus) ? true : false}
                    closeOnDocumentClick={true}
                    position='top center'
                    closeIcon
                    size="large"
                    onClose={this.props.setModalClose}
                    >

                    <Modal.Content >
                    {favorite_scenes  ?
                        <Modal.Description >
                           
                           
            
                   
                            <Segment inverted style={{ marginBottom: '0', marginTop: '0', borderRadius: 0 }} >
                                <Grid >
                                    <Header inverted  style={{width:'100%'}} className="nomargin" >Your Favorite Scenes:</Header>
                                    <Card.Group style={{ margin: '0' }} itemsPerRow={(isMobile) ? (isTablet) ? '3': '2' : ((favorite_scenes ) && (favorite_scenes.length>2 ))?'4':'2'} >
                                        {(favorite_scenes ) ? favorite_scenes.map((item, i) => {
                                           
                                               
                                            return(  
                                                            <FavoriteContainer key={`scene${i}`} set={item}  cdntime={this.props.zoomState.cdntime} zoomstate={zoommState} unfavourite={this.unfavourite}/>
                                                     
                                                              )
                                            
                                           // return ritem;
                                        }) : this.renderProgress()}
                                    </Card.Group>

                                </Grid>
                            </Segment>
                            
                        </Modal.Description>
                        :
                        <Modal.Description ><h1>Sorry..., you don't have favorited items saved</h1></Modal.Description>
                        }
                    </Modal.Content>
                </Modal></div>)
    }

}
const mapSetsToProps = state => ({ 
    UserConfiguration: state.setUserConfiguration
    , sets_data: state.setsSiteController.sets_data
    , itemsHolder: state.setItmesContainer
    , favorite_scenes: state.getFavoriteScenes.favorite_scenes
    , ModalStatus: state.setModalStatus.ModalStatus
    , levelZoom: state.levelZoom.levelZoom
    ,zoomState: state.zoomState });
const mapDispatchToPropsActions = dispatch => ({
    setModalStatus: value => dispatch(setModalStatus(value)),
    SetFavouriteScene: value => dispatch(SetFavouriteScene(value)),
    UnsetFavouritem: value => dispatch(UnsetFavouritem(value)),
    getModelScenes: value => dispatch(getModelScenes(value)),
    


});
export default connect(mapSetsToProps, mapDispatchToPropsActions)(Favorites);

