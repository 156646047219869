import { SET_PRELOADCOORDS } from '../actions'


export const preLoadCorrds = (state=null, action) => {
    
    switch (action.type) {
        case SET_PRELOADCOORDS:
          
         return {...state,
                    coords:action.payload.coords,
             }
      
        default:
           return {...state}
           
    }
  //  return state;
}