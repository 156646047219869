import { SET_LEVELZOOM } from '../actions'


export const levelZoom = (state=null, action) => {
    
    switch (action.type) {
        case SET_LEVELZOOM:
          
         return {...state,
            levelZoom:action.payload.levelZoom,
                    
             }
      
        default:
           return {...state}
           
    }
  //  return state; 
}