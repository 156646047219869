import { SET_VIDEOLOADED } from '../actions'


export const videoloaded = (state=null, action) => {
    
    switch (action.type) {
        case SET_VIDEOLOADED:
          
         return {...state,
                    Item:action.payload.Item,

             }
      
        default:
           return {...state}
           
    }
  //  return state;
}