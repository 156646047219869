import { GET_FAVORITESCENES,DELETE_COMMENT } from '../actions'


export const getFavoriteScenes = (state=null, action) => {
    
    switch (action.type) {
      
        case GET_FAVORITESCENES:
          
         return {...state,
            favorite_scenes:action.payload.favorite_scenes,
            }
        case DELETE_COMMENT:
          
         return   state.favorite_scenes.filter(( item) => item.Id !== action.payload.Id);
      
        default:
           return {...state}
           
    }
  //  return state;
}